import React from 'react';
import { Box } from '@shared/ui/box';
import { DeleteOutlined } from '@ant-design/icons';
import { DraggableCancel } from '@shared/constants';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { largeSize } from '@shared/constants/builderCardSizes';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  Button,
  Form,
  Input,
  InputRef,
  Modal,
  Select,
  Switch,
  Typography,
} from 'antd';
import {
  useDispatch,
  useSelector,
  selectCounter,
  onUpdateExtraDataItem,
} from '@shared/redux';

export const currency: any = {
  dollar: {
    label: '$',
    value: 'dollar',
  },
  euro: {
    label: '€',
    value: 'euro',
  },
  pound: {
    label: '£',
    value: 'pound',
  },
};

export const CRUDServiceList: React.FC<{
  data: any;
  onRemoveItem?: any;
  dataLength?: number;
  initialData?: {
    rate?: string;
    title: string;
    currency?: any;
    index?: number;
  };
  isModalOpen: boolean;
  addServiceItem?: (data: any) => void;
  onAddItem?: (e: React.MouseEvent<HTMLDivElement>) => void;
  setInitialData?: React.Dispatch<React.SetStateAction<any>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  data,
  onAddItem,
  dataLength,
  initialData,
  isModalOpen,
  onRemoveItem,
  setIsModalOpen,
  setInitialData,
  addServiceItem,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const matches768 = useBreakpoint(768);
  const counter = useSelector(selectCounter);
  const inputRef = React.useRef<InputRef>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [
    { rate, title, rateIsEnabled, currency: localCurrency },
    updateFormData,
  ] = React.useState({
    rate: '',
    title: '',
    rateIsEnabled: true,
    currency: currency.dollar,
  });

  React.useEffect(() => {
    if (initialData) {
      const { rate, currency: lCurrency, ...rest } = initialData;

      updateFormData({
        ...rest,
        rateIsEnabled: !!rate,
        rate: !!rate ? `${rate}` : '',
        currency: !!rate ? (currency as any)[lCurrency] : currency.dollar,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const afterClose = () => {
    buttonRef.current?.removeAttribute('disabled');

    updateFormData({
      rate: '',
      title: '',
      rateIsEnabled: true,
      currency: currency.dollar,
    });
  };

  const addNewItem = () => {
    if (typeof onAddItem === 'function') {
      const uid = `${data.name}_+_${counter}`;

      let value: Record<string, string | number> = {
        title,
      };

      if (rateIsEnabled) {
        value = {
          title,
          rate: Number(rate),
          currency: localCurrency.value,
        };
      }

      dispatch(
        onUpdateExtraDataItem({
          i: uid,
          item: {
            value,
            key: 'value',
          },
        }),
      );

      onAddItem({
        // @ts-ignore
        currentTarget: {
          dataset: {
            type: data.name,
            w: `${largeSize.w}`,
            h: `${largeSize.h}`,
            platform: `${data.platform}`,
          },
        },
      });

      try {
        posthog?.capture(PosthogEvents.ServiceListAddRates);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (typeof setInitialData === 'function') {
      setInitialData(undefined);
    }
  };

  const selectOnChange = (value: any) => {
    updateFormData((prevState) => {
      return {
        ...prevState,
        currency: typeof value === 'string' ? currency[value] : value,
      };
    });
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const handleSwitchOnChange = (checked: boolean) => {
    if (!checked) {
      updateFormData((prevState) => ({
        ...prevState,
        rateIsEnabled: checked,
        rate: '',
      }));

      try {
        posthog?.capture(PosthogEvents.ServiceListToggle);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    } else {
      updateFormData((prevState) => ({
        ...prevState,
        rateIsEnabled: checked,
      }));
    }
  };

  const handleRateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      (/^(\d)*(\.)*([0-9]{1,2})?$/.test(e.target.value) ||
        e.target.value === '') &&
      Number(e.target.value) <= 100000 &&
      e.target.value?.length <= 9
    ) {
      updateFormData((prevState) => ({
        ...prevState,
        rate: e.target.value?.trim(),
      }));
    }
  };

  const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  let saveBtnIsDisabled = !title?.trim();

  if (rateIsEnabled) {
    saveBtnIsDisabled = !title?.trim() || !rate?.trim();
  }

  const handleOk = () => {
    buttonRef.current?.setAttribute('disabled', 'disabled');

    if (typeof onAddItem === 'function') {
      addNewItem();
    }

    if (typeof addServiceItem === 'function') {
      let index = undefined;
      if (initialData?.index !== undefined) {
        index = initialData.index;
      }

      addServiceItem({ rate, title, rateIsEnabled, localCurrency, index });
    }

    handleCancel();
  };

  const selectBefore = (
    <Select<{ value: string; label: string }>
      labelInValue={false}
      value={localCurrency}
      onChange={selectOnChange}
      disabled={!rateIsEnabled}
      options={Object.values(currency)}
    />
  );

  const footer = (
    <Box boxStyles={{ justifyContent: 'space-between' }}>
      {initialData && dataLength && dataLength > 1 ? (
        <Button
          danger
          type="link"
          icon={<DeleteOutlined />}
          style={{ paddingLeft: 0 }}
          onClick={() => {
            setDeleteModalOpen(true);
          }}
        >
          Delete
        </Button>
      ) : (
        <div />
      )}
      <Box boxStyles={{ gap: 8, width: 'initial' }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type="primary"
          ref={buttonRef}
          onClick={handleOk}
          disabled={saveBtnIsDisabled}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <style>{'.add-new-service-content{transform: translate(0,0);}'}</style>
      <Modal
        okText="Save"
        footer={footer}
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        afterClose={afterClose}
        title="Add New Service"
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName={DraggableCancel}
        width={matches ? undefined : 400}
        style={{
          transform: 'translate(0,0)',
          top: matches768 ? 20 : undefined,
        }}
        classNames={{ content: 'add-new-service-content' }}
      >
        <Form
          id="aaaa"
          size="large"
          component="div"
          layout="vertical"
          style={{ position: 'relative' }}
        >
          <Form.Item label="Service Name" required>
            <Input
              type="text"
              name="title"
              value={title}
              ref={inputRef}
              autoComplete="off"
              onChange={handleTitleOnChange}
              placeholder="e.g. Instagram Reel"
            />
          </Form.Item>
          <Form.Item
            label="Rate"
            colon={true}
            required={rateIsEnabled}
            style={{ position: 'relative' }}
          >
            <Input
              type="text"
              name="rate"
              value={rate}
              autoComplete="off"
              disabled={!rateIsEnabled}
              addonBefore={selectBefore}
              onChange={handleRateOnChange}
              placeholder={rateIsEnabled ? 'e.g. 100' : 'Ask Me'}
            />
          </Form.Item>

          <Box
            boxStyles={{
              gap: '2.4rem',
              alignItems: 'center',
              borderRadius: '.8rem',
              marginBottom: '2.4rem',
              padding: '.8rem 1.2rem',
              backgroundColor: '#F5F5F5',
              justifyContent: 'space-between',
            }}
          >
            <Typography.Text
              style={{
                fontSize: 12,
                lineHeight: '20px',
              }}
            >
              {rateIsEnabled ? (
                'Click to hide your service rate.'
              ) : (
                <>
                  Your service rate is hidden. Visitors can request a custom
                  quote through the{' '}
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      lineHeight: '20px',
                    }}
                    strong
                  >
                    &quot;Ask Me&quot;
                  </Typography.Text>{' '}
                  button.
                </>
              )}
            </Typography.Text>
            <Switch checked={rateIsEnabled} onChange={handleSwitchOnChange} />
          </Box>
        </Form>
      </Modal>
      <Modal
        centered
        width={300}
        okText="Delete"
        onOk={() => {
          onRemoveItem(initialData?.index);
          handleCancel();
        }}
        cancelText="Cancel"
        open={deleteModalOpen}
        title="Delete Service"
        className={DraggableCancel}
        onCancel={() => setDeleteModalOpen(false)}
        okButtonProps={{ danger: true, ghost: true }}
        getContainer={() =>
          document.getElementsByClassName(
            'add-new-service-content',
          )?.[0] as HTMLElement
        }
      />
    </>
  );
};
