export enum PosthogEvents {
  AddedBio = 'added_bio',
  InfoCard = 'info_card',
  AddedName = 'added_name',
  TourClosed = 'tour_closed',
  LinkCopied = 'link_copied',
  static = 'background_upload',
  NotifyClick = 'notify_click',
  ImageChange = 'image_change',
  TallyClosed = 'tally_closed',
  VideoChange = 'video_change',
  ServiceList = 'service_list',
  HomeClicked = 'home_clicked',
  AddedSocials = 'added_socials',
  UpgradeClick = 'upgrade_click',
  AddedCountry = 'added_country',
  ShareClicked = 'share_clicked',
  ThemingColor = 'theming_color',
  ThemingClick = 'theming_click',
  ThemingFonts = 'theming_fonts',
  ImageAddLink = 'image_add_link',
  VideoAddLink = 'video_add_link',
  RegisterStep1 = 'register_step1',
  RegisterStep2 = 'register_step2',
  RegisterStep3 = 'register_step3',
  VerifyClicked = 'verify_clicked',
  TourCompleted = 'tour_completed',
  AddedCategory = 'added_category',
  TextComponent = 'text_component',
  unsplash = 'background_unsplash',
  LinkComponent = 'link_component',
  SeeAllClicked = 'see_all_clicked',
  CreateMkClick = 'create_mk_click',
  TitleComponent = 'title_component',
  TemplatesClick = 'templates_click',
  VideoComponent = 'video_component',
  TallyCompleted = 'tally_completed',
  ImageComponent = 'image_component',
  CategorySearch = 'category_search',
  BackgroundType = 'background_type',
  AddCompClicked = 'add_comp_clicked',
  SwitcherClicked = 'switcher_clicked',
  SelectedScratch = 'selected_scratch',
  viral_mango = 'background_viralmango',
  GetStartedClick = 'get_started_click',
  ServiceListEdit = 'service_list_edit',
  GalleryComponent = 'gallery_component',
  SelectedTemplate = 'selected_template',
  DeletedComponent = 'deleted_component',
  CreateNewClicked = 'create_new_clicked',
  ThemingBackground = 'theming_background',
  PartnerBtnClicked = 'partner_btn_clicked',
  ServiceListToggle = 'service_list_toggle',
  OnboardingComplete = 'onboarding_complete',
  EngagedWithMediakit = 'engaged_with_mediakit',
  ServiceListAddRates = 'service_list_add_rates',
  MoreThanAllowedSize = 'more_than_allowed_size',
}
