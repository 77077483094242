import React from 'react';
import { CSSObject } from '@emotion/styled';
import theme, { fastAnimation } from '@shared/theme';

const { token } = theme;

export const htmlStyles: CSSObject = {
  fontSize: 10,
  minWidth: 360,
  width: '100%',
  height: '100%',
  display: 'block',
  background: token!.colorBgLayout,
};

export const bodyStyles: CSSObject = {
  margin: 0,
  width: '100%',
  height: '100%',
  display: 'block',
  background: token!.colorBgLayout,
};

export const layoutStyles: React.CSSProperties = {
  minWidth: 360,
  display: 'flex',
  minHeight: '100%',
  background: '#F5F5F5',
  transition: `opacity ${fastAnimation} linear`,
};
