import React from 'react';
import * as Icons from '@shared/ui/Icons';
import { ActionButton } from '@shared/ui/action-button';
import type { ShapeActionProps } from '../types';

export const CircleAction: React.FC<ShapeActionProps> = ({
  isActive,
  setSizes,
}) => {
  return (
    <ActionButton tabIndex={-1} onClick={setSizes}>
      <Icons.CircleIcon isActive={isActive} />
    </ActionButton>
  );
};
