import React from 'react';
import { Button } from 'antd';
import { onChangeActiveItem, useDispatch } from '@shared/redux';

export const DoneAction: React.FC = () => {
  const dispatch = useDispatch();
  const onDone = () => dispatch(onChangeActiveItem(undefined));

  return (
    <Button onClick={onDone} type="default">
      <span
        style={{
          fontSize: 14,
          display: 'flex',
          fontWeight: 600,
          color: '#5956E9',
          lineHeight: '22px',
        }}
      >
        Done
      </span>
    </Button>
  );
};
