import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const PostSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => {
  const isS14 = size === builderCardSizes.s14;

  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`&.${prefixCls}-skeleton`]: {
      width: '100%',
      height: '100%',
      [`& > .${prefixCls}-skeleton-content`]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [`& > .${prefixCls}-skeleton-title`]: {
          width: '100%',
          height: '100%',
          marginBottom: 0,
          maxHeight: isS14 ? 56 : 'unset',
        },
      },
    },
  };
});
