import React from 'react';
import { Flex } from 'antd';
import { DoneAction } from './done-action';
import { CircleAction } from './circle-action';
import { ReplaceAction } from './replace-action';
import { OvalVerticalAction } from './oval-vertical-action';
import { OvalHorizontalAction } from './oval-horizontal-action';
import { BottomActionsContainer } from './bottom-actions-container';
import {
  useSelector,
  useDispatch,
  selectAvatarIsLoading,
  onChangeProfileAvatarShape,
} from '@shared/redux';
import {
  profileSizes,
  profileAvatarShapes,
} from '@shared/constants/profileSizes';
import type { ProfileBottomActionsProps } from '../types';
import { ActionsDivider } from '@widgets/mediakit/MediaKit/Common/Actions/ui/actions-divider';

export const ProfileBottomActions: React.FC<ProfileBottomActionsProps> = (
  props,
) => {
  const { activeSize, username, slug } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(selectAvatarIsLoading);

  const setSizes = (shape: profileAvatarShapes) => () => {
    dispatch(onChangeProfileAvatarShape(shape));
  };

  return !isLoading ? (
    <BottomActionsContainer
      isActive
      id="resizeMobileTarget"
      className="actions-container"
    >
      <Flex align="center" gap=".6rem">
        <CircleAction
          isActive={activeSize === profileSizes.s160160}
          setSizes={setSizes(profileAvatarShapes.circle)}
        />
        <OvalHorizontalAction
          isActive={activeSize === profileSizes.s220160}
          setSizes={setSizes(profileAvatarShapes.oval_horizontal)}
        />
        <OvalVerticalAction
          isActive={activeSize === profileSizes.s160220}
          setSizes={setSizes(profileAvatarShapes.oval_vertical)}
        />
        <ActionsDivider type="vertical" />
        <ReplaceAction slug={slug} username={username} widthHelper />
      </Flex>
      <DoneAction />
    </BottomActionsContainer>
  ) : null;
};
