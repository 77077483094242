import React from 'react';
import { Builder } from '@/lib/types/builder';
import { Actions } from '../../../Common/Actions';
import { useSelector, selectActiveItem } from '@shared/redux';
import { YoutubeCard } from '@widgets/social-card/ui/youtube-card';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { SocialCardContainer } from '@widgets/social-card/ui/social-card-container';

export function Profile({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);
  const className = activeItem === dataGrid.i ? 'active-item' : '';
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  return (
    <SocialCardContainer
      key={dataGrid.i}
      data-grid={dataGrid}
      className={className}
    >
      <Actions index={index} dataGrid={dataGrid} />

      <YoutubeCard
        data={data}
        activeSize={activeSize}
        dataServer={dataServer}
        showSkeleton={showSkeleton}
      />
    </SocialCardContainer>
  );
}
