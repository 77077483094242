import { Divider } from 'antd';
import styled from '@emotion/styled';

export const ActionsDivider = styled(Divider)(() => ({
  height: 20,
  marginTop: 'auto',
  position: 'static',
  marginBottom: 'auto',
  backgroundColor: '#FFFFFF80',
}));
