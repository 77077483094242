import { CSSObject } from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const cardStyles: CSSObject = {
  padding: '1.6rem',
  borderRadius: '2.8rem',
};

export const trendsStyles: CSSObject = {
  gap: '.4rem',
  width: 'unset',
  display: 'inline-flex',
};

export const trendsTitleStyles: CSSObject = {
  margin: 0,
};

export const cardBodyHeaderStyles: CSSObject = {
  gap: '.4rem',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};

export const cardBodyDescriptionStyles: CSSObject = {
  gap: '.8rem',
  alignItems: 'baseline',
};

export const cardBodyHeaderDetailsStyles: CSSObject = {
  gap: '.4rem',
  alignItems: 'center',
};

export const titleTitleProps = {
  width: 111,
  style: { height: 24, margin: 0 },
};

export const descriptionTitleProps = {
  style: {
    height: 46,
    maxWidth: 270,
    marginBottom: 0,
    marginTop: '1rem',
  },
};

export const cardBodyHeaderDetailsTitleStyles = (
  size: builderCardSizes,
): CSSObject => {
  return {
    whiteSpace: 'nowrap',
    lineHeight: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
      ? '2rem'
      : '2.4rem',
    fontSize: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
      ? '1.2rem'
      : '1.6rem',
  };
};

export const cardBodyDescriptionTitleStyles = (
  size: builderCardSizes,
): CSSObject => ({
  lineHeight: '4.6rem',
  fontSize: builderCardSizes.s12 === size ? '3.5rem' : '5.5rem',
});

export const cardBodyStyles = (size: builderCardSizes): CSSObject => ({
  flexDirection: 'column',
  gap: size === builderCardSizes.s24 ? '3rem' : '1.8rem',
});
