import React from 'react';

export enum profileSizes {
  s160160 = '160160',
  s220160 = '220160',
  s160220 = '160220',
}

export enum profileAvatarShapes {
  circle = 1,
  oval_horizontal = 2,
  oval_vertical = 3,
}

export const profileAvatarStyles: Record<
  profileAvatarShapes,
  React.CSSProperties
> = {
  [profileAvatarShapes.circle]: {
    width: 160,
    height: 160,
    aspectRatio: 1,
    borderRadius: '50%',
  },
  [profileAvatarShapes.oval_vertical]: {
    width: 160,
    height: 220,
    borderRadius: 160,
    aspectRatio: 8 / 11,
  },
  [profileAvatarShapes.oval_horizontal]: {
    width: 220,
    height: 160,
    borderRadius: 160,
    aspectRatio: 11 / 8,
  },
};
