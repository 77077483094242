import styled from '@emotion/styled';
import { breakpoints, fastAnimation } from '@shared/theme';

export const MoveActionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive: boolean }) => {
  return {
    bottom: 0,
    right: '0',
    zIndex: 11,
    width: '4rem',
    height: '4rem',
    display: 'none',
    padding: '.5rem',
    borderRadius: '50%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isActive ? 1 : 0,
    backgroundColor: '#001529',
    transition: `opacity ${fastAnimation} ease-in-out`,
    transform: 'translate(calc(50% - .8rem), calc(50% - .8rem))',
    [`@media (max-width: ${breakpoints.lg})`]: {
      display: 'flex',
    },
  };
});
