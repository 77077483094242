import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const SocialCardHead = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => {
  const fd = [
    builderCardSizes.s12,
    builderCardSizes.s14,
    builderCardSizes.s22,
  ].includes(size);
  const isS24 = size === builderCardSizes.s24;
  const fs = [builderCardSizes.s12, builderCardSizes.s14].includes(size);

  return {
    display: 'flex',
    gap: isS24 ? '1rem' : 0,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: fd ? 'column' : 'row',
    [`& > h1.${prefixCls}-typography`]: {
      maxWidth: '100%',
      fontSize: fs ? '3rem' : void 0,
    },
    '& > div': {
      width: isS24 ? '40%' : '100%',
    },
  };
});
