import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const HandleSkeleton = styled(Skeleton)(() => ({
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [`&.${prefixCls}-skeleton`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 14,
        marginBottom: 0,
      },
    },
  },
}));
