import styled from '@emotion/styled';

export const UploadInfoBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));
