import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const FollowersSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: [builderCardSizes.s24, builderCardSizes.s22].includes(size) ? 46 : 36,
  [`&.${prefixCls}-skeleton`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 26,
        marginBottom: 0,
      },
    },
  },
}));
