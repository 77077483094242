import React from 'react';
import { Resize21Icon } from '@shared/ui/Icons';
import type { CommonActionProps } from '../types';
import { ActionButton } from '@shared/ui/action-button';
import { onUpdateReactGridItem, useDispatch } from '@shared/redux';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { useBeforeResize } from '@widgets/mkit-tour/hooks/use-before-resize';

export const Resize22: React.FC<CommonActionProps> = ({ index, isActive }) => {
  const dispatch = useDispatch();
  const { beforeResize } = useBeforeResize();

  const onResize = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    beforeResize();

    const [w, h] = builderCardSizes.s22.split('');

    dispatch(onUpdateReactGridItem({ i: index, item: { w: +w, h: +h } }));
  };

  return (
    <ActionButton tabIndex={-1} onClick={onResize}>
      <Resize21Icon isActive={isActive} />
    </ActionButton>
  );
};
