import styled from '@emotion/styled';
import React from 'react';
import clsx from 'clsx';

export const BottomActionsContainer = ({ className, ...props }: any) => {
  return (
    <Test
      {...props}
      className={clsx(
        className,
        'animate__animated animate__slideInUp animate__faster',
      )}
    />
  );
};

export const Test = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive: boolean }) => {
  return {
    left: 0,
    right: 0,
    opacity: 1,
    bottom: 16,
    zIndex: 103,
    gap: '3rem',
    minHeight: 56,
    minWidth: 350,
    display: 'none',
    padding: '1rem',
    position: 'fixed',
    border: '1px solid',
    borderRadius: '1rem',
    alignItems: 'center',
    marginInline: 'auto',
    width: 'fit-content',
    background: '#001529',
    backgroundColor: '#001529',
    transition: `transform .2s ease-in-out`,
    transform: `translate(0, ${isActive ? '-1.6rem' : '100%'})`,
    '@media (max-width: 991px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '@media (max-width: 575px)': {
      width: 'calc(100% - 32px)',
      justifyContent: 'space-between',
    },
    '@media (max-width: 410px)': {
      gap: 0,
    },
    '@media (max-width: 380px)': {
      width: 'calc(100% - 8px)',
    },
  };
});
