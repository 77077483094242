'use client';

import Link from 'next/link';
import React, { Ref } from 'react';
import styled from '@emotion/styled';
import { ErrorBoundary } from '@shared/ui/error-boundary';
import { breakpoints, fastAnimation, gridItemShadow } from '@shared/theme';
import { ComponentNames } from '@shared/constants/componentNames';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  [`@media (min-width: ${breakpoints.lg})`]: {
    willChange: 'transform',
    transition: 'transform .3s ease-in',
    '& > a.react-grid-item.server': {
      willChange: 'box-shadow',
      transition: 'box-shadow .3s ease-in',
    },
    '&:hover': {
      transform: 'translate(8px,-8px)',
      '& > a.react-grid-item.server': {
        boxShadow: '-8px 8px rgba(0,0,0,1)',
      },
    },
  },
}));

const ReactGridItem = React.forwardRef(
  (
    {
      style,
      onClick,
      children,
      className,
      onMouseUp,
      onTouchEnd,
      onMouseDown,
      ...props
    }: React.ComponentProps<'div'>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const dataElem = (props as any)['data-elem'];
    const dataGrid = (props as any)['data-data'];
    const isServer = (props as any)['data-server'];

    if (isServer && dataGrid.link) {
      if (dataElem === ComponentNames.basics_video) {
        return (
          <ErrorBoundary
            fallback={
              <Link
                href="/"
                style={style}
                target="_blank"
                className={className}
              >
                {children}
              </Link>
            }
          >
            <div style={style} className={className}>
              {children}
            </div>
          </ErrorBoundary>
        );
      }

      return (
        <ErrorBoundary
          fallback={
            <Link style={style} target="_blank" href="/" className={className}>
              {children}
            </Link>
          }
        >
          <StyledDiv>
            <Link
              style={style}
              rel="nofollow"
              target="_blank"
              href={dataGrid.link}
              className={className}
            >
              {children}
            </Link>
          </StyledDiv>
        </ErrorBoundary>
      );
    }

    return (
      <div
        ref={ref}
        style={style}
        onClick={onClick}
        className={className}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        {...props}
      >
        {children}
      </div>
    );
  },
);

ReactGridItem.displayName = 'GridItemComponent';

export const GridItem = styled(ReactGridItem)(() => ({
  '&.react-grid-item.active': {
    zIndex: 92,
  },
  '&.react-grid-item > div': {
    width: '100%',
    height: '100%',
    borderRadius: 24,
    position: 'relative',
    backgroundColor: '#ffffff',
    transition: 'border-color 400ms ease-in-out',
  },
  '&.react-grid-item.title-component': {
    borderRadius: 8,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&.active': {
      backgroundColor: 'transparent',
      '& > div': {
        backgroundColor: 'rgba(255,255,255,0.5)',
      },
    },
    '& > div': {
      padding: 0,
      borderRadius: 8,
      backgroundColor: 'transparent',
      transition:
        'background-color 400ms ease-in-out,border-color 400ms ease-in-out',
    },
  },
  '&.react-grid-item > div:not(.image-component)': {
    border: '4px solid transparent',
  },
  '&.react-grid-item': {
    zIndex: 91,
    borderRadius: 24,
    boxSizing: 'border-box',
    boxShadow: gridItemShadow,
    backgroundColor: '#ffffff',
    transition: `all ${fastAnimation} ease`,
    transitionProperty: 'left, top, transform, width, height',
  },
  '&.react-grid-item:not(.active, .server) *': {
    pointerEvents: 'none',
  },
  '&.react-grid-item.server .actions-container': {
    display: 'none',
  },
  '&.react-grid-item > div.active-item': {
    borderColor: '#001529',
    willChange: 'border-color',
  },
  '&.react-grid-item.all-selected > div': {
    willChange: 'border-color',
    border: '4px solid #001529',
  },
  '&.react-grid-item > div.active-item.image-component': {
    borderWidth: '4px',
    borderStyle: 'solid',
    willChange: 'border-color',
  },
  '&.react-grid-item > .react-resizable-handle': {
    display: 'none',
  },
  '&.react-grid-item.react-draggable-dragging': {
    zIndex: 93,
    cursor: 'grabbing',
    transition: 'none',
    willChange: 'transition',
  },
  '&.react-grid-item.react-draggable-dragging *': {
    pointerEvents: 'none',
  },
  '&.react-grid-item.react-draggable-dragging > div': {
    transform: 'scale(1.1)',
    transition: `transform ${fastAnimation} ease`,
  },
  '&.react-grid-item img': {
    transition: `all ${fastAnimation} ease`,
  },
  '&.react-grid-item.resizing': {
    opacity: 0.9,
    willChange: 'left, top, transform, width, height',
  },
}));
