import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const SocialCardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => {
  const isS22 = size === builderCardSizes.s22;
  return {
    gap: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isS22 ? 'row' : 'column',
    alignItems: isS22 ? 'center' : 'initial',
    '& > div': {
      width: isS22 ? '50%' : '100%',
    },
  };
});
