import React from 'react';
import { Image } from 'antd';

interface PreviewProps {
  value: string;
  thumb: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Preview: React.FC<PreviewProps> = ({
  thumb,
  value,
  visible,
  setVisible,
}) => {
  const toolbarRender = () => null;

  const onVisibleChange = (value: boolean) => {
    setVisible(value);
  };

  const imageRender = () => (
    <video
      muted
      controls
      src={value}
      width="100%"
      height="100%"
      style={{ objectFit: 'cover', objectPosition: 'center' }}
    />
  );

  return (
    <Image
      alt=""
      width={0}
      height={0}
      src={thumb}
      style={{ display: 'none' }}
      preview={{
        visible,
        imageRender,
        toolbarRender,
        onVisibleChange,
        destroyOnClose: true,
      }}
    />
  );
};
