import React from 'react';
import { App } from 'antd';
import Cookies from 'universal-cookie';
import Dropzone, { DropzoneFile } from 'dropzone';
import { allowedVideos } from '@shared/constants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

interface ReactDropzoneProps {
  id: string;
  onAddedfile: (file: DropzoneFile) => void;
  onSuccess: (file: DropzoneFile, response: Object | string) => void;
  onSending: (
    file: DropzoneFile,
    xhr: XMLHttpRequest,
    formData: FormData,
  ) => void;
}

export const ReactDropzone: React.FC<
  React.PropsWithChildren<ReactDropzoneProps>
> = ({ id, children, onSuccess, onSending, onAddedfile }) => {
  const { message } = App.useApp();
  const posthog = useLocalPostHog();
  const dropzoneRef = React.useRef<Dropzone | null>(null);

  const addedfile = React.useCallback(
    (file: Dropzone.DropzoneFile) => {
      const isAllowedFormat = allowedVideos.includes(file.type);

      if (!isAllowedFormat) {
        message.error('You can only upload mp4 file!').then(console.log);

        return false;
      }

      const isAllowedSize = file.size / 1024 / 1024 < 1024;

      if (!isAllowedSize) {
        message.error('Video must smaller than 1GB!').then();

        try {
          posthog?.capture(PosthogEvents.MoreThanAllowedSize, {
            type: 'video',
            size: file.size,
          });
        } catch (e) {
          console.error('Posthog error:', e);
        }

        return false;
      }

      onAddedfile(file);

      dropzoneRef.current?.uploadFile(file);
    },
    [message, onAddedfile, posthog],
  );

  React.useEffect(() => {
    if (dropzoneRef.current || !id) {
      return;
    }

    const cookies = new Cookies();
    const access_token = cookies.get('access_token') ?? null;

    dropzoneRef.current = new Dropzone(`#${id}`, {
      maxFiles: 1,
      chunking: true,
      autoQueue: false,
      paramName: 'file',
      maxFilesize: 1024,
      uploadMultiple: false,
      autoProcessQueue: false,
      previewsContainer: false,
      chunkSize: 1024 * 1024 * 2,
      acceptedFiles: allowedVideos.join(','),
      url: '/api/mediakit-builder/upload-video',
      headers: { Authorization: `Bearer ${access_token}` },
    });

    dropzoneRef.current
      .on('sending', onSending)
      .on('success', onSuccess)
      .on('addedfile', addedfile);
  }, [id, message, addedfile, onSuccess, onSending, onAddedfile]);

  return (
    <form className="dropzone" id={id}>
      <input
        type="file"
        name="file"
        maxLength={1}
        style={{ display: 'none' }}
        accept={allowedVideos.join(',')}
      />
      <div className="dz-default dz-message">
        <div className="dz-button">{children}</div>
      </div>
    </form>
  );
};
