import React from 'react';
import { Box } from '@shared/ui/box';
import { sleep } from '@/lib/utils/sleep';
import { CheckOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Select } from 'antd';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { useSubmitServiceContactFormMutation } from '@/lib/services/contactCreator';

export const ContactForm = ({
  index,
  handle,
  toggleModal,
  serviceList,
}: any) => {
  const [submit] = useSubmitServiceContactFormMutation();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      username: handle,
      service: serviceList?.[index]?.title || '',
    },
  });

  const onSubmit = async (formValues: any) => {
    try {
      await sleep(400);

      await submit({
        ...formValues,
      }).unwrap();
    } catch (e: any) {
      Object.keys(e.errors || {}).forEach((inputName) => {
        if (allowedInputs.includes(inputName)) {
          setError(inputName, { message: e.errors[inputName] });
        } else if (inputName === 'handle') {
          setError('root', { message: e.errors[inputName] });
        }
      });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form layout="vertical" component="div">
        {!!errors.root?.message && (
          <Box boxStyles={{ marginBottom: 24 }}>
            <Alert type="error" message={errors.root?.message} closable />
          </Box>
        )}

        <Controller
          name="service"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Service"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Select
                size="large"
                options={serviceList.map(
                  (service: any) =>
                    ({
                      value: service.title,
                      label: service.title,
                    }) as any,
                )}
                {...field}
              />
              {/*<Input*/}
              {/*  {...field}*/}
              {/*  type="text"*/}
              {/*  size="large"*/}
              {/*  name="service"*/}
              {/*  autoComplete="off"*/}
              {/*/>*/}
            </Form.Item>
          )}
        />

        <Controller
          name="brand_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Brand Name"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="text"
                name="brand_name"
                size="large"
                autoComplete="off"
              />
            </Form.Item>
          )}
        />
        <Controller
          name="full_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Full Name"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="text"
                name="full_name"
                autoComplete="off"
                size="large"
              />
            </Form.Item>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Email"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input
                {...field}
                type="email"
                name="email"
                autoComplete="email"
                size="large"
              />
            </Form.Item>
          )}
        />

        <Controller
          name="message"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              required
              label="Message"
              help={error?.message}
              validateStatus={!!error?.message ? 'error' : undefined}
            >
              <Input.TextArea {...field} size="large" rows={4} name="message" />
            </Form.Item>
          )}
        />
      </Form>

      <Box boxStyles={{ gap: 8, justifyContent: 'flex-end' }}>
        <Button size="large" onClick={() => toggleModal(null)}>
          Cancel
        </Button>

        <SubmitButton toggleModal={toggleModal} control={control} />
      </Box>
    </form>
  );
};

const allowedInputs = [
  'email',
  'service',
  'message',
  'full_name',
  'brand_name',
];

const SubmitButton: React.FC<any> = ({ control, toggleModal }) => {
  const { isSubmitting, isSubmitSuccessful } = useFormState({ control });

  const [email, full_name, brand_name, message] = useWatch({
    control,
    name: ['email', 'full_name', 'brand_name', 'message'],
  });

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      setTimeout(function () {
        toggleModal(null);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const disabled =
    !email?.trim() ||
    !message?.trim() ||
    !full_name?.trim() ||
    !brand_name?.trim();

  return (
    <Button
      size="large"
      type="primary"
      htmlType="submit"
      disabled={disabled}
      loading={isSubmitting}
      icon={isSubmitSuccessful ? <CheckOutlined /> : undefined}
    >
      Send
    </Button>
  );
};
