import React from 'react';
import { Flex } from 'antd';
import { DoneAction } from './done-action';
import { ActionButton } from '@shared/ui/action-button';
import { TitleExtraActions } from '@widgets/mediakit/MediaKit/Basics/Title';
import {
  AlignLeftOutlined,
  AlignRightOutlined,
  AlignCenterOutlined,
} from '@shared/ui/Icons';
import {
  useDispatch,
  useSelector,
  selectExtraData,
  selectActiveItem,
  onUpdateExtraDataItem,
} from '@shared/redux';

export const TitleBottomActions: React.FC = () => {
  const dispatch = useDispatch();
  const extraData = useSelector(selectExtraData);
  const activeItem = useSelector(selectActiveItem);

  const data = extraData[activeItem as any] as any;

  const changeAlignment = (alignment: string) => () => {
    dispatch(
      onUpdateExtraDataItem({
        i: activeItem as any,
        item: {
          key: 'align',
          value: alignment,
        },
      }),
    );
  };

  return (
    <>
      <Flex gap=".6rem" align="center">
        <ActionButton onClick={changeAlignment('left')}>
          <AlignLeftOutlined isActive={data.align === 'left' || !data.align} />
        </ActionButton>
        <ActionButton onClick={changeAlignment('center')}>
          <AlignCenterOutlined isActive={data.align === 'center'} />
        </ActionButton>
        <ActionButton onClick={changeAlignment('right')}>
          <AlignRightOutlined isActive={data.align === 'right'} />
        </ActionButton>
        <TitleExtraActions
          uuid={activeItem!}
          color={data.bg_color || '#000000'}
        />
      </Flex>
      <DoneAction />
    </>
  );
};
