import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { useParams, useSearchParams } from 'next/navigation';

const viralmangoIcon = (
  <svg
    width="82"
    height="20"
    viewBox="0 0 82 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.2638 10.2462C77.2638 11.1775 76.4991 11.9521 75.5579 11.9521C74.6265 11.9521 73.852 11.1873 73.852 10.2462V10.0501C73.852 9.11872 74.6167 8.34421 75.5579 8.34421H77.2638V10.2462ZM77.2638 6.22656H75.5579C73.4501 6.22656 71.7344 7.94225 71.7344 10.0501V10.2462C71.7344 12.354 73.4501 14.0697 75.5579 14.0697C77.6657 14.0697 79.3814 12.354 79.3814 10.2462V8.35401C79.3814 7.18735 78.4403 6.22656 77.2638 6.22656Z"
      fill="#5956E9"
    />
    <path
      d="M11.5 6.54883L8.45098 13.9214H6.03922L3 6.55863H5.28431L7.2549 11.3233L9.22549 6.55863H11.5V6.54883Z"
      fill="#5956E9"
    />
    <path
      d="M14.5395 13.9214H12.4316V6.54883H14.5395V13.9214Z"
      fill="#5956E9"
    />
    <path
      d="M25.3133 11.8122C24.6663 12.0769 23.931 11.9298 23.431 11.4298C22.931 10.9396 22.7839 10.2044 23.0584 9.54749C23.3231 8.90043 23.9604 8.48866 24.6565 8.48866C25.6173 8.48866 26.382 9.27298 26.382 10.2142C26.3624 10.9298 25.9506 11.5573 25.3133 11.8122ZM27.9016 8.21415C27.5388 7.61612 26.9996 7.13572 26.3624 6.81219C25.8231 6.53768 25.2349 6.39062 24.6369 6.39062C23.0486 6.39062 21.6369 7.36121 21.0584 8.85141C20.48 10.3416 20.8918 12.0083 22.0584 13.0769C22.4996 13.4887 23.029 13.773 23.578 13.9298C24.2545 14.1259 24.9702 14.1259 25.6565 13.9298C25.8918 13.871 26.1271 13.773 26.3526 13.6651V13.9298H28.4604V10.224C28.48 9.51808 28.2741 8.822 27.9016 8.21415Z"
      fill="#5956E9"
    />
    <path d="M31.9711 13.9216H29.8633V3H31.9711V13.9216Z" fill="#5956E9" />
    <path
      d="M20.8042 7.23606L19.314 8.72626C19.0983 8.51057 18.7748 8.45175 18.5003 8.56939C18.2258 8.68704 18.0297 8.95175 18.0297 9.26547V13.9419H15.9219V9.25567C15.9219 7.76547 16.618 6.30469 18.7062 6.30469C19.3631 6.30469 20.2748 6.69684 20.8042 7.23606Z"
      fill="#5956E9"
    />
    <path
      d="M44.7359 9.75337V13.92H42.628V9.75337C42.628 9.05729 42.0594 8.49847 41.3731 8.49847C41.03 8.49847 40.6967 8.64553 40.4712 8.87102C40.2457 9.09651 40.0986 9.42004 40.0986 9.77298V13.9396H37.9908V9.75337C37.9908 9.05729 37.4221 8.49847 36.7359 8.49847C36.0398 8.49847 35.481 9.0671 35.481 9.75337V13.92H33.3535V9.75337C33.3535 7.89063 34.8927 6.39062 36.7457 6.39062C37.6084 6.39062 38.4221 6.72396 39.0594 7.322C39.6869 6.72396 40.5104 6.39062 41.3731 6.39062C43.2261 6.39062 44.7359 7.89063 44.7359 9.75337Z"
      fill="#5956E9"
    />
    <path
      d="M61.7353 9.75536V13.922H59.6274V9.75536C59.6274 9.05928 59.0588 8.50046 58.3725 8.50046C57.6862 8.50046 57.1176 9.06909 57.1176 9.75536V13.922H55.0098V9.75536C55.0098 7.95144 56.7058 6.38281 58.3725 6.38281C60.2353 6.39262 61.7353 7.89262 61.7353 9.75536Z"
      fill="#5956E9"
    />
    <path
      d="M66.785 11.9611C65.8242 11.9611 65.0595 11.1768 65.0595 10.2356C65.0595 9.29446 65.8438 8.51015 66.785 8.51015C67.7458 8.51015 68.5105 9.29446 68.5105 10.2356C68.5105 11.1768 67.7458 11.9513 66.785 11.9611ZM68.383 6.75525C67.883 6.52976 67.3536 6.41211 66.785 6.41211C65.3242 6.41211 63.981 7.25525 63.3438 8.55917C62.7065 9.86309 62.8634 11.4513 63.7654 12.5984C64.2654 13.2454 64.9516 13.7062 65.7359 13.9317C66.0791 14.0298 66.432 14.0788 66.7752 14.0788C67.1379 14.0788 67.4908 14.0298 67.8144 13.9317C69.0301 13.5886 70.0203 12.6572 70.4222 11.4121C70.785 10.2847 70.5987 9.08858 69.9614 8.1376C69.5203 7.51015 68.9908 7.05917 68.383 6.75525Z"
      fill="#5956E9"
    />
    <path
      d="M50.452 11.8122C49.805 12.0769 49.0697 11.9298 48.5697 11.4298C48.0697 10.9396 47.9226 10.2044 48.1873 9.54749C48.452 8.90043 49.0893 8.48866 49.7853 8.48866C50.7461 8.48866 51.5108 9.27298 51.5108 10.2142C51.501 10.9298 51.0795 11.5573 50.452 11.8122ZM53.0402 8.21415C52.6677 7.61612 52.1383 7.13572 51.501 6.81219C50.9618 6.53768 50.3736 6.39062 49.7755 6.39062C48.1873 6.39062 46.7755 7.36121 46.1971 8.85141C45.6187 10.3318 46.0304 12.0083 47.1971 13.0769C47.6383 13.4887 48.1677 13.773 48.7167 13.9298C49.3932 14.1259 50.1089 14.1259 50.7952 13.9298C51.0304 13.871 51.2657 13.773 51.4912 13.6651V13.9298H53.5991V10.224C53.6187 9.51808 53.403 8.822 53.0402 8.21415Z"
      fill="#5956E9"
    />
    <path
      d="M70.6178 14.8633C70.6178 15.1672 70.5785 15.4613 70.5099 15.7554C70.1178 17.4319 68.5982 18.6966 66.7942 18.6966C64.9903 18.6966 63.4707 17.4515 63.0785 15.7554C63.0099 15.4711 62.9707 15.1672 62.9707 14.8633H65.0785C65.0785 15.1868 65.1766 15.4907 65.3138 15.7554C65.6178 16.2554 66.1668 16.5986 66.7746 16.5986C67.4021 16.5986 67.9413 16.2652 68.2354 15.7554C68.3923 15.4907 68.4707 15.1868 68.4707 14.8633H70.6178Z"
      fill="#5956E9"
    />
    <path
      d="M13.4807 5.13718C14.0655 5.13718 14.5395 4.66313 14.5395 4.07835C14.5395 3.49358 14.0655 3.01953 13.4807 3.01953C12.8959 3.01953 12.4219 3.49358 12.4219 4.07835C12.4219 4.66313 12.8959 5.13718 13.4807 5.13718Z"
      fill="#5956E9"
    />
  </svg>
);

const TypographyText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    fontSize: '1.2rem',
    lineHeight: '2.2rem',
  },
}));

const Container = styled('div')(() => ({
  gap: '0.4rem',
  display: 'flex',
  alignItems: 'center',
  marginInline: 'auto',
  justifyContent: 'center',
  '& a': {
    display: 'inline-flex',
  },
}));

const PoweredByBody: React.FC = () => {
  const searchString = useSearchParams().toString();

  const params = useParams();
  const username = params.username;

  let viralmangoHref = `https://viralmango.com/?utm_source=mkit_share_get_started&utm_content=${username}`;
  if (searchString) {
    viralmangoHref = `${viralmangoHref}&${searchString}`;
  }

  return (
    <Container>
      <TypographyText>Powered by</TypographyText>
      <a target="_blank" href={viralmangoHref}>
        {viralmangoIcon}
      </a>
    </Container>
  );
};

export const PoweredBy: React.FC = () => {
  return (
    <React.Suspense>
      <PoweredByBody />
    </React.Suspense>
  );
};
