import React from 'react';
import styled from '@emotion/styled';

export const ProfileAvatarContainer = styled('div')(
  ({ selected }: { selected?: boolean }) => ({
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    width: 'max-content',
    position: 'absolute',
    marginInline: 'auto',
    justifyContent: 'center',
    zIndex: selected ? 92 : 91,
    transform: 'translate(0, -80px)',
  }),
);

export const ImageWrapper = styled('div')(() => ({
  display: 'flex',
  borderRadius: '50%',
  position: 'relative',
  backgroundColor: '#FFF',
}));

export function getImageStyles(
  selected: boolean,
  styles: React.CSSProperties,
): React.CSSProperties {
  return {
    objectFit: 'cover',
    objectPosition: 'center',
    willChange: 'width, height, border',
    border: `6px solid ${selected ? '#001529' : '#FFF'}`,
    transition: 'width .2s ease, height .2s ease, border .2s ease',
    ...styles,
  };
}

export const avatarUploaderStyles: React.CSSProperties = {
  width: 160,
  height: 160,
};
