import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const SocialIconSkeleton = styled(Skeleton.Avatar)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    [`& > .${prefixCls}-skeleton-avatar`]: {
      borderRadius: '50%',
    },
  },
}));
