import React from 'react';
import styled from '@emotion/styled';

export const UploadInfoContainer = styled('div')(() => ({
  gap: 4,
  height: 32,
  fontSize: 12,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
