export enum builderCardSizes {
  s12 = '12',
  s14 = '14',
  s22 = '22',
  s24 = '24',
}

/**
 * Common Components Default Sizes
 *
 * Instagram, YouTube and TikTok account components.
 */
export enum commonSizes {
  w = 2,
  h = 2,
}

/**
 * Large Components Default Sizes
 *
 * Instagram, YouTube and TikTok account components.
 * Image and Link components.
 */
export enum largeSize {
  w = 2,
  h = 4,
}

/**
 * Title Components Default Sizes
 *
 * Title components.
 */
export enum titleComponentSize {
  w = 4,
  h = 1,
}
