'use client';

import clsx from 'clsx';
import React from 'react';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Builder } from '@/lib/types/builder';
import { Input, Skeleton, Typography } from 'antd';
import { DraggableCancel } from '@shared/constants';
import { fontVariants } from '@shared/constants/fontVariants';
import { useSkeletonLoader } from '@/lib/hooks/useSkeletonLoader';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
} from '@shared/redux';

function InputTitle({
  uid,
  size,
  value,
}: {
  uid: string;
  value?: string;
  size: builderCardSizes;
}) {
  const dispatch = useDispatch();
  const [localValue, setLocalValue] = React.useState('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.add(DraggableCancel);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          key: e.target.name as 'title',
          value: localValue?.trim()?.slice(0, 50),
        },
      }),
    );
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const fontSize = [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '1.2rem'
    : '1.6rem';

  return (
    <Input
      name="title"
      autoComplete="off"
      value={localValue}
      placeholder="Title"
      variant="borderless"
      className="fonts-opt3"
      onBlur={handleInputOnBlur}
      onFocus={handleInputOnFocus}
      onChange={handleInputOnChange}
      style={{
        fontSize,
        padding: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onPressEnter={handleOnPressEnter}
    />
  );
}

function InputValue({
  uid,
  size,
  value,
}: {
  uid: string;
  value?: string;
  size: builderCardSizes;
}) {
  const dispatch = useDispatch();
  const [localValue, setLocalValue] = React.useState('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          key: e.target.name as 'value',
          value: localValue?.trim()?.slice(0, 50),
        },
      }),
    );
  };

  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.add(DraggableCancel);
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const fontSize = builderCardSizes.s12 === size ? '3.5rem' : '5.5rem';

  return (
    <Input
      name="value"
      placeholder="Info"
      autoComplete="off"
      value={localValue}
      variant="borderless"
      onBlur={handleInputOnBlur}
      onFocus={handleInputOnFocus}
      className={fontVariants.opt2}
      onChange={handleInputOnChange}
      style={{
        fontSize,
        padding: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onPressEnter={handleOnPressEnter}
    />
  );
}

export const cardBodyHeaderDetailsTitleStyles = (
  size: builderCardSizes,
): React.CSSProperties => ({
  padding: 0,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  height: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '2rem'
    : '2.4rem',
  lineHeight: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '2rem'
    : '2.4rem',
  fontSize: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '1.2rem'
    : '1.6rem',
});

export function InfoCard({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
  showSkeleton: loading,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);
  const skeleton = useSkeletonLoader(disableSkeleton);
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  const showSkeleton = loading || skeleton;

  return (
    <InfoCardContainer
      key={dataGrid.i}
      data-grid={dataGrid}
      className={clsx(activeItem === dataGrid.i && 'active-item')}
    >
      <InfoCardHead>
        {dataServer ? (
          <Text
            size={activeSize}
            ellipsis={{ tooltip: true }}
            className={fontVariants.opt3}
          >
            {data?.title}
          </Text>
        ) : showSkeleton ? (
          <InfoCardHeadSkeleton
            paragraph={false}
            active={showSkeleton}
            showSkeleton={showSkeleton}
          />
        ) : (
          <InputTitle uid={dataGrid.i} size={activeSize} value={data?.title} />
        )}
      </InfoCardHead>

      <InfoCardBody>
        {showSkeleton ? (
          <InfoCardBodySkeleton
            active
            paragraph={false}
            showSkeleton={showSkeleton}
          />
        ) : (
          <InfoCardMeta size={activeSize} showSkeleton={showSkeleton}>
            {dataServer ? (
              <Typography.Title
                ellipsis
                level={1}
                className={fontVariants.opt2}
              >
                {data?.value}
              </Typography.Title>
            ) : (
              <InputValue
                uid={dataGrid.i}
                size={activeSize}
                value={data?.value}
              />
            )}
          </InfoCardMeta>
        )}
      </InfoCardBody>

      <Actions
        index={index}
        dataGrid={dataGrid}
        allowedSizes={[builderCardSizes.s12, builderCardSizes.s22]}
      />
    </InfoCardContainer>
  );
}

const InfoCardHead = styled('div')(() => ({
  zIndex: 1,
  gap: '.4rem',
  width: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

const InfoCardBody = styled('div')(() => ({
  top: 0,
  left: 0,
  flexGrow: 1,
  gap: '.8rem',
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  paddingTop: '1.6rem',
  paddingInline: '1.6rem',
}));

const InfoCardContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '1.6rem',
  borderRadius: '2.8rem',
  flexDirection: 'column',
}));

const Text = styled(Typography.Text, {
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }: { size: builderCardSizes }) => ({
  [`&.${prefixCls}-typography`]: {
    padding: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
      ? '2rem'
      : '2.4rem',
    lineHeight: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
      ? '2rem'
      : '2.4rem',
    fontSize: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
      ? '1.2rem'
      : '1.6rem',
  },
}));

const InfoCardHeadSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['showSkeleton'].includes(prop),
})<{ showSkeleton?: boolean }>(({ showSkeleton }) => ({
  display: showSkeleton ? 'table' : 'none',
  [`& .${prefixCls}-skeleton-content .${prefixCls}-skeleton-title`]: {
    width: 111,
    height: 24,
    marginBottom: 0,
  },
}));

const InfoCardBodySkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['showSkeleton'].includes(prop),
})<{ showSkeleton?: boolean }>(({ showSkeleton }) => ({
  display: showSkeleton ? 'table' : 'none',
  [`& .${prefixCls}-skeleton-content .${prefixCls}-skeleton-title`]: {
    height: 46,
    maxWidth: 270,
    marginBottom: 0,
  },
}));

const InfoCardMeta = styled('div', {
  shouldForwardProp: (prop) => !['size', 'showSkeleton'].includes(prop),
})(
  ({
    size,
    showSkeleton,
  }: {
    size: builderCardSizes;
    showSkeleton?: boolean;
  }) => ({
    gap: '.8rem',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'baseline',
    opacity: showSkeleton ? 0 : 1,
    [`& h1.${prefixCls}-typography`]: {
      marginBottom: 0,
      fontSize: builderCardSizes.s12 === size ? '3.5rem' : '5.5rem',
    },
  }),
);
