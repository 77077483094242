import styled from '@emotion/styled';
import { breakpoints, fastAnimation } from '@shared/theme';

export const DeleteActionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive: boolean }) => {
  return {
    top: 0,
    left: 0,
    zIndex: 93,
    right: '50%',
    width: '4rem',
    height: '4rem',
    display: 'flex',
    padding: '.5rem',
    borderRadius: '50%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isActive ? 1 : 0,
    backgroundColor: '#001529',
    transform: 'translate(-50%, -50%)',
    transition: `opacity ${fastAnimation} ease-in-out`,
    [`@media (max-width: ${breakpoints.lg})`]: {
      transform: 'translate(calc(-50% + .8rem), calc(-50% + .8rem))',
    },
  };
});
