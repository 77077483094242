import styled from '@emotion/styled';
import React, { forwardRef } from 'react';

const videoStyle: React.CSSProperties = {
  objectFit: 'cover',
  objectPosition: 'center',
};

const VideoPlayerContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
}));

export const VideoPlayer = forwardRef<
  HTMLVideoElement,
  {
    play: boolean;
    muted: boolean;
    thumb?: string;
    value?: string;
    setPlay: React.Dispatch<boolean>;
    setMuted: React.Dispatch<boolean>;
  }
>(function VideoPlayer({ play, setPlay, thumb, value, muted, setMuted }, ref) {
  if (!value) {
    return null;
  }

  const videoOnClick = () => {
    if (play) {
      setPlay(false);
    }
  };

  const sourceOnClick = () => {
    if (play) {
      setPlay(false);
    }
  };

  let type = 'video/mp4';

  if (value?.toLowerCase()?.endsWith('.mov')) {
    type = 'video/quicktime';
  }

  return (
    <VideoPlayerContainer>
      <video
        ref={ref}
        src={value}
        width="100%"
        height="100%"
        muted={muted}
        preload="auto"
        poster={thumb}
        autoPlay={false}
        controls={false}
        playsInline={true}
        style={videoStyle}
        onClick={videoOnClick}
        onPlay={() => setPlay(true)}
        onPause={() => setPlay(false)}
        onVolumeChange={(e) => setMuted(e.currentTarget.muted)}
      ></video>
      {/*<source src={value} onClick={sourceOnClick}/>*/}
    </VideoPlayerContainer>
  );
});
