import { Upload } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const Uploader = styled(Upload)(() => ({
  display: 'flex',
  [`& .${prefixCls}-upload`]: {
    display: 'flex',
  },
}));
