import React from 'react';
import { fastAnimationMS } from '@shared/theme';

export function useSkeletonLoader(disableSkeleton?: boolean) {
  const [showSkeleton, toggleSkeleton] = React.useState<boolean>(
    !disableSkeleton,
  );

  React.useEffect(() => {
    setTimeout(() => {
      toggleSkeleton(false);
    }, fastAnimationMS);
  }, []);

  return showSkeleton;
}
