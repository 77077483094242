'use client';

import React from 'react';
import dayjs from 'dayjs';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import * as styles from './styles';
import { Box } from '@shared/ui/box';
import { Skeleton, Tooltip } from 'antd';
import { Text } from '@shared/ui/typography';
import { Builder } from '@/lib/types/builder';
import { Actions } from '../../../Common/Actions';
import ttIcon from '@shared/icons/tiktok-light.svg';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSkeletonLoader } from '@/lib/hooks/useSkeletonLoader';
import { getTikTokAccountDetails } from '@widgets/mediakit/helpers';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { defaultEndColor, defaultStartColor } from '@shared/constants';
import {
  selectActiveItem,
  selectMediakitTheme,
  selectMediakitThemeFonts,
  useSelector,
} from '@shared/redux';

const Area = dynamic(
  () => import('@ant-design/plots').then((mod) => mod.Area),
  {
    ssr: false,
  },
);

function Content({
  data,
  dataServer,
  disableSkeleton,
  activeSize: size,
}: {
  dataServer?: boolean;
  disableSkeleton?: boolean;
  data: Builder.GridComponentProps['data'];
  activeSize: builderCardSizes.s12 | builderCardSizes.s22;
}) {
  const match400 = useBreakpoint(400);
  const match991 = useBreakpoint(991);
  const fonts = useSelector(selectMediakitThemeFonts);

  const theme = useSelector(selectMediakitTheme);
  const paletteColors = theme?.shuffled_palette?.split(',');
  let endColor = defaultEndColor;
  let startColor = defaultStartColor;
  if (Array.isArray(paletteColors)) {
    endColor = paletteColors?.[0];
  }
  if (theme?.primary_color) {
    startColor = theme.primary_color;
  }

  let areaWidth = !match991 ? 324 : 328;
  const areaHeight = 270;

  if (match400) {
    areaWidth = 300;
  }

  const showSkeleton = useSkeletonLoader(disableSkeleton);

  const detailsFunc = getTikTokAccountDetails()[data.name];
  const { title, tooltip } = detailsFunc({
    size,
  });

  const tt = (data?.data || []).map((item: any) => {
    return item.value;
  });

  const max = Math.max(...tt);
  const min = Math.min(...tt);

  return (
    <Box boxStyles={styles.cardBodyStyles(size)}>
      <Box boxStyles={styles.cardBodyHeaderStyles}>
        <Skeleton
          active
          paragraph={false}
          title={styles.titleTitleProps}
          style={{ display: showSkeleton ? 'table' : 'none' }}
        />
        <Box
          boxStyles={Object.assign({}, styles.cardBodyHeaderDetailsStyles, {
            opacity: showSkeleton ? 0 : 1,
          })}
        >
          <Image src={ttIcon} alt="TikTok" />
          <Text
            className="fonts-opt3"
            textProps={styles.cardBodyHeaderDetailsTitleStyles(size)}
          >
            {title}
          </Text>
        </Box>

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{
                fontSize: '2rem',
                color: '#00000073',
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          height: '100%',
          display: showSkeleton ? 'flex' : 'none',
          '& .mediakit-skeleton.mediakit-skeleton-element': {
            width: '100%',
            height: '100%',
            '& .mediakit-skeleton-image': {
              width: '100%',
              height: '100%',
            },
          },
        })}
      >
        <Skeleton.Node> </Skeleton.Node>
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: showSkeleton ? 'none' : 'flex',
        })}
      >
        {dataServer && (
          <Box
            boxStyles={{
              zIndex: 10,
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
          />
        )}
        <React.Suspense>
          <Area
            line={{
              style: { stroke: endColor },
            }}
            axis={{
              y: {
                labelFormatter: '~s',
                labelFontFamily: fonts?.opt3.family_name,
              },
              x: {
                labelFontFamily: fonts?.opt3.family_name,
                labelFormatter: (value: string) => dayjs(value).format('MMM'),
              },
            }}
            scale={{
              y: {
                clamp: true,
                domain: [min, max],
              },
            }}
            xField="month"
            yField="value"
            tooltip={false}
            width={areaWidth}
            height={areaHeight}
            style={{ opacity: 0.4, fill: startColor }}
            data={{ type: 'inline', value: data?.data || [] }}
          />
        </React.Suspense>
      </Box>
    </Box>
  );
}

export function LikesFollowersGrowth({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as
    | builderCardSizes.s22
    | builderCardSizes.s12;

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={activeItem === dataGrid.i ? 'active-item' : ''}
    >
      <Content
        data={data}
        dataServer={dataServer}
        activeSize={activeSize}
        disableSkeleton={disableSkeleton}
      />
      <Actions index={index} dataGrid={dataGrid} disableActions />
    </Box>
  );
}
