'use client';

import clsx from 'clsx';
import React from 'react';
import Dropzone from 'dropzone';
import { Mask } from './ui/mask';
import { Host } from './ui/host';
import * as styles from './styles';
import { Preview } from './ui/preview';
import { VideoCard } from './ui/video-card';
import { Builder } from '@/lib/types/builder';
import { PlayButton } from './ui/play-button';
import { VideoPlayer } from './ui/video-player';
import { VideoActions } from './ui/video-actions';
import { DraggableCancel } from '@shared/constants';
import { SkeletonImage } from './ui/skeleton-image';
import { ReactDropzone } from './ui/react-dropzone';
import { VideoCardBody } from './ui/video-card-body';
import { Layout as ILayout } from 'react-grid-layout';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { UploadInfoCard } from './ui/upload-info-card';
import { ActionButton } from '@shared/ui/action-button';
import { LinkIcon, ReplaceIcon } from '@shared/ui/Icons';
import { LoadingContainer } from './ui/loading-container';
import { Form, Input, Modal, Divider, InputRef } from 'antd';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
  selectUploadImageStates,
  videoUploadMatchPending,
  videoUploadMatchFulfilled,
} from '@shared/redux';

interface ContentProps {
  dataGrid: ILayout;
  dataServer?: boolean;
  showSkeleton?: boolean;
  disableSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}

const Content: React.FC<ContentProps> = ({ data, dataGrid, showSkeleton }) => {
  const is768 = useBreakpoint(768);
  const [play, setPlay] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const activeItem = useSelector(selectActiveItem);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const uploadImageStates = useSelector(selectUploadImageStates);

  const isLoading = uploadImageStates[dataGrid.i] || showSkeleton;

  const isActive = activeItem === dataGrid.i;
  const uploadInfo = uploadImageStates[dataGrid.i];

  const maskOnClick = async () => {
    if (play) {
      videoRef.current?.pause();

      setPlay(false);
    } else {
      await videoRef.current?.play();

      setPlay(true);
    }
  };

  return (
    <VideoCardBody>
      {isLoading ? (
        <LoadingContainer>
          <SkeletonImage active />
          <UploadInfoCard uploadInfo={uploadInfo} />
        </LoadingContainer>
      ) : data?.value ? (
        <>
          <Mask onClick={maskOnClick} />
          <Host link={data.link} isLoading={isLoading} />
          <VideoPlayer
            play={play}
            muted={muted}
            ref={videoRef}
            setPlay={setPlay}
            value={data?.value}
            thumb={data?.thumb}
            setMuted={setMuted}
          />
          <VideoActions
            muted={muted}
            setMuted={setMuted}
            videoRef={videoRef}
            setVisible={setVisible}
            isActive={isActive && is768}
          />
          <PlayButton play={play} setPlay={setPlay} videoRef={videoRef} />
          <Preview
            visible={visible}
            setVisible={setVisible}
            value={data?.value || ''}
            thumb={data?.thumb || ''}
          />
        </>
      ) : null}
    </VideoCardBody>
  );
};

export function Video({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const inputRef = React.useRef<InputRef>(null);
  const activeItem = useSelector(selectActiveItem);

  const [value, handleOnChange] = React.useState(data.link);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  React.useEffect(() => {
    handleOnChange(data?.link || '');
  }, [data?.link]);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim() || !!data?.link) {
      let newValue = value?.trim();
      if (newValue && !newValue?.startsWith('http')) {
        newValue = `https://${newValue}`;
      }
      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'link',
            value: newValue || '',
          },
        }),
      );

      if (newValue && newValue !== data?.link) {
        try {
          posthog?.capture(PosthogEvents.VideoAddLink);
        } catch (e) {
          console.error('Posthog error:', e);
        }
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    handleOnChange(data?.link || '');
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const onSending = React.useCallback(
    (file: Dropzone.DropzoneFile, _xhr: XMLHttpRequest, formData: FormData) => {
      formData.append('uid', dataGrid.i);

      dispatch(
        videoUploadMatchPending({
          uid: dataGrid.i,
          data: {
            totalSize: file.size,
            bytesSent: file.upload?.bytesSent,
          },
        }),
      );
    },

    [dataGrid.i, dispatch],
  );

  const onSuccess = React.useCallback(
    (_file: Dropzone.DropzoneFile, response: Object | string) => {
      if (typeof response === 'string') {
        return;
      }

      if ('uid' in response && 'url' in response && 'thumb_url' in response) {
        if (
          typeof response.url === 'string' &&
          typeof response.uid === 'string' &&
          typeof response.thumb_url === 'string'
        ) {
          dispatch(
            videoUploadMatchFulfilled({
              uid: response.uid,
              url: response.url,
              thumb_url: response.thumb_url,
            }),
          );
        }
      }

      posthog?.capture(PosthogEvents.VideoChange);
    },
    [dispatch, posthog],
  );

  const onAddedfile = React.useCallback(
    (_file: Dropzone.DropzoneFile) => {
      dispatch(videoUploadMatchPending({ uid: dataGrid.i }));
    },
    [dispatch, dataGrid.i],
  );

  const cardClassName = clsx(
    'image-component',
    activeItem === dataGrid.i && 'active-item',
  );

  return (
    <VideoCard key={dataGrid.i} data-grid={dataGrid} className={cardClassName}>
      <Content
        data={data}
        dataGrid={dataGrid}
        activeSize={activeSize}
        dataServer={dataServer}
        showSkeleton={showSkeleton}
        disableSkeleton={disableSkeleton}
      />
      <Modal
        okText="Save"
        onOk={handleOk}
        title="Add Link"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName={DraggableCancel}
        width={matches ? undefined : 360}
        okButtonProps={{ disabled: !value?.trim() && !data?.link }}
      >
        <Form size="large" layout="vertical" component="div">
          <Form.Item label="Link">
            <Input
              type="url"
              name="link"
              value={value}
              ref={inputRef}
              autoComplete="off"
              placeholder="www.example.com"
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Actions
        index={index}
        dataGrid={dataGrid}
        extraActions={
          <>
            <Divider type="vertical" style={styles.dividerStyles} />
            <ReactDropzone
              onSuccess={onSuccess}
              onSending={onSending}
              onAddedfile={onAddedfile}
              id={dataGrid.i?.replace('_+_', '')}
            >
              <ActionButton type="button">
                <ReplaceIcon widthHelper />
              </ActionButton>
            </ReactDropzone>
            <ActionButton onClick={showModal}>
              <LinkIcon widthHelper />
            </ActionButton>
          </>
        }
      />
    </VideoCard>
  );
}
