import styled from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const HeadDetails = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => {
  const gp = [builderCardSizes.s12, builderCardSizes.s22].includes(size);
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: gp ? '.6rem' : '1.4rem',
  };
});
