'use client';

import React from 'react';
import Image from 'next/image';
import * as styles from './styles';
import dynamic from 'next/dynamic';
import { Box } from '@shared/ui/box';
import { Skeleton, Tooltip, Typography } from 'antd';
import { Text } from '@shared/ui/typography';
import { Builder } from '@/lib/types/builder';
import { Actions } from '../../../Common/Actions';
import { InfoCircleOutlined } from '@ant-design/icons';
import igIcon from '@shared/icons/instagram-light.svg';
import {
  selectActiveItem,
  selectMediakitTheme,
  selectMediakitThemeFonts,
  useSelector,
} from '@shared/redux';
import { useSkeletonLoader } from '@/lib/hooks/useSkeletonLoader';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { getInstagramAccountDetails } from '@widgets/mediakit/helpers';
import { prefixCls } from '@shared/theme';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { fontVariants } from '@shared/constants/fontVariants';
import { defaultEndColor, defaultStartColor } from '@shared/constants';

const sizeMap: Record<builderCardSizes, { width: number; height: number }> = {
  [builderCardSizes.s24]: {
    width: 200,
    height: 200,
  },
  [builderCardSizes.s22]: {
    width: 90,
    height: 90,
  },
  [builderCardSizes.s14]: {
    width: 122,
    height: 122,
  },
  [builderCardSizes.s12]: {
    width: 60,
    height: 60,
  },
};

// @ts-ignore
const Pie = dynamic(() => import('@ant-design/plots').then((mod) => mod.Pie), {
  ssr: false,
  loading: () => <Skeleton.Node active> </Skeleton.Node>,
});

function Content({
  data,
  dataServer,
  disableSkeleton,
  activeSize: size,
}: {
  dataServer?: boolean;
  disableSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}) {
  const match991 = useBreakpoint(991);
  const fonts = useSelector(selectMediakitThemeFonts);
  const showSkeleton = useSkeletonLoader(disableSkeleton);

  const theme = useSelector(selectMediakitTheme);

  const paletteColors = theme?.shuffled_palette?.split(',');

  let endColor = defaultEndColor;
  let startColor = defaultStartColor;

  if (Array.isArray(paletteColors)) {
    endColor = paletteColors?.[0];
  }

  if (theme?.primary_color) {
    startColor = theme.primary_color;
  }

  const detailsFunc = getInstagramAccountDetails()[data.name];
  const { title, tooltip } = detailsFunc({
    size,
  } as any);

  let tmp: any = {};
  const localData = (data?.data || []).map((item: any) => {
    tmp[item.code] = Number((item.weight * 100).toFixed(1));
    return {
      type: item.code,
      value: Number((item.weight * 100).toFixed(1)),
    };
  });

  const chartContainerStyles: any = {
    [builderCardSizes.s24]: {
      gap: 25,
      alignItems: 'center',
      paddingTop: match991 ? '2rem' : '4rem',
    },
    [builderCardSizes.s22]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: match991 ? '.6rem' : '1.6rem',
    },
    [builderCardSizes.s14]: {
      gap: '2.4rem',
      height: 'initial',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [builderCardSizes.s12]: {
      alignItems: 'center',
      paddingTop: match991 ? '.4rem' : '1.2rem',
    },
  };

  const [sizes, setSizes] = React.useState<
    { width: number; height: number } | undefined
  >();

  React.useEffect(() => {
    setSizes(sizeMap[size]);
  }, [size]);

  const label =
    builderCardSizes.s24 === size
      ? {
          text: 'value',
          formatter: (item: any) => `${item}%`,
          style: {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: fonts?.opt3.family_name,
          },
          transform: [
            {
              type: 'contrastReverse',
            },
          ],
        }
      : false;

  return (
    <Box boxStyles={styles.cardBodyStyles(size)}>
      <Box boxStyles={styles.cardBodyHeaderStyles}>
        <Skeleton
          active
          paragraph={false}
          title={styles.titleTitleProps}
          style={{ display: showSkeleton ? 'table' : 'none' }}
        />
        <Box
          boxStyles={Object.assign({}, styles.cardBodyHeaderDetailsStyles, {
            opacity: showSkeleton ? 0 : 1,
          })}
        >
          <Image src={igIcon} alt="Instagram" />
          <Text
            className={fontVariants.opt3}
            textProps={styles.cardBodyHeaderDetailsTitleStyles(size)}
          >
            {title}
          </Text>
        </Box>

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{
                fontSize: '2rem',
                color: '#00000073',
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Box
        boxStyles={Object.assign({}, styles.cardBodyDescriptionStyles, {
          height: '100%',
          paddingTop: 24,
          display: showSkeleton ? 'flex' : 'none',
          '& .mediakit-skeleton.mediakit-skeleton-element': {
            width: '100%',
            height: '100%',
            '& .mediakit-skeleton-image': {
              width: '100%',
              height: '100%',
            },
          },
        })}
      >
        <Skeleton.Node> </Skeleton.Node>
      </Box>
      <Box
        boxStyles={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          transition: 'none',
          position: 'relative',
          flexDirection: 'column',
          display: showSkeleton ? 'none' : 'flex',
          ...chartContainerStyles[size],
          '& > div:first-of-type': {
            width: `${sizes?.width}px !important`,
            height: `${sizes?.height}px !important`,
            '& .mediakit-skeleton-image': {
              width: `${sizes?.width}px !important`,
              height: `${sizes?.height}px !important`,
            },
          },
        }}
      >
        {dataServer && (
          <Box
            boxStyles={{
              zIndex: 10,
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
          />
        )}
        <React.Suspense>
          {sizes && (
            <Pie
              margin={0}
              padding={0}
              label={label}
              legend={false}
              tooltip={false}
              colorField="type"
              angleField="value"
              innerRadius={0.55}
              width={sizes.width}
              height={sizes.height}
              data={{ type: 'inline', value: localData || [] }}
              scale={{ color: { palette: [endColor, startColor] } }}
            />
          )}
        </React.Suspense>
        <Box
          boxStyles={{
            alignItems: 'center',
            flexDirection: 'column',
            width: size !== builderCardSizes.s24 ? 130 : 'max-content',
            [`& .${prefixCls}-typography`]: {
              color: '#00000073',
              fontSize: '1.2rem',
            },
          }}
        >
          <Box
            boxStyles={{
              gap: 16,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box boxStyles={{ alignItems: 'center', gap: 8 }}>
              <Box
                boxStyles={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: endColor,
                }}
              />
              <Typography.Text className={fontVariants.opt3}>
                Male
              </Typography.Text>
            </Box>

            {size !== builderCardSizes.s24 && (
              <Typography.Text
                className={fontVariants.opt3}
                style={{ whiteSpace: 'nowrap' }}
                strong
              >
                {tmp.MALE}%
              </Typography.Text>
            )}
          </Box>

          <Box
            boxStyles={{
              gap: 16,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box boxStyles={{ alignItems: 'center', gap: 8 }}>
              <Box
                boxStyles={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: startColor,
                }}
              />
              <Typography.Text className={fontVariants.opt3}>
                Female
              </Typography.Text>
            </Box>

            {size !== builderCardSizes.s24 && (
              <Typography.Text
                className={fontVariants.opt3}
                style={{ whiteSpace: 'nowrap' }}
                strong
              >
                {tmp.FEMALE}%
              </Typography.Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function SexRatio({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);

  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={activeItem === dataGrid.i ? 'active-item' : ''}
    >
      <Content
        data={data}
        dataServer={dataServer}
        activeSize={activeSize}
        disableSkeleton={disableSkeleton}
      />
      <Actions index={index} dataGrid={dataGrid} />
    </Box>
  );
}
