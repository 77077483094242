import React from 'react';
import styled from '@emotion/styled';
import { DraggableCancel } from '@shared/constants';

const Div = styled('div', {
  shouldForwardProp: (prop) => prop !== 'play',
})(({ play }: { play: boolean }) => ({
  zIndex: 10,
  cursor: 'pointer',
  borderRadius: '50%',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: 'white',
  display: play ? 'none' : 'flex',
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

const playIcon = (
  <svg
    width="42"
    height="42"
    fill="none"
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="42" height="42" rx="21" fill="white" />
    <path
      fill="#5956E9"
      d="M16.2959 28.1074C15.6455 28.1074 15.2412 27.6064 15.2412 26.8418V14.4844C15.2412 13.7197 15.6455 13.2275 16.2959 13.2275C16.6475 13.2275 16.9463 13.3594 17.2979 13.5615L27.5459 19.4941C28.2754 19.916 28.5303 20.1973 28.5303 20.6631C28.5303 21.1289 28.2754 21.4102 27.5459 21.8408L17.2979 27.7646C16.9463 27.9668 16.6475 28.1074 16.2959 28.1074Z"
    />
  </svg>
);

export const PlayButton: React.FC<{
  play: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  setPlay: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ play, setPlay, videoRef }) => {
  const onClick = () => {
    videoRef.current?.play().then(() => {
      setPlay(true);
    });
  };

  return (
    <Div play={play} onClick={onClick} className={DraggableCancel}>
      {playIcon}
    </Div>
  );
};
