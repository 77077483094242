import React from 'react';
import { Flex } from 'antd';
import { Resize12 } from './resize12';
import { Resize22 } from './resize22';
import { Resize14 } from './resize14';
import { Resize24 } from './resize24';
import { DoneAction } from './done-action';
import type { TextBottomActionsProps } from '../types';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { ExtraActions } from '@widgets/mediakit/MediaKit/Basics/TextComponent';
import {
  useSelector,
  selectExtraData,
  selectActiveItem,
  selectCurrentLayout,
} from '@shared/redux';

export const TextBottomActions: React.FC<TextBottomActionsProps> = ({
  activeSize,
}) => {
  const extraData = useSelector(selectExtraData);
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);

  const data: any = extraData[activeItem as any];

  const activeIndex = layout.findIndex((e) => e.i === activeItem);

  const dataGrid = layout[activeIndex];

  return (
    <>
      <Flex gap=".6rem" align="center">
        <Resize12
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s12}
        />
        <Resize22
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s22}
        />
        <Resize14
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s14}
        />
        <Resize24
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s24}
        />
        <ExtraActions
          uuid={dataGrid.i}
          align={data.align}
          color={data.bg_color || '#000000'}
        />
      </Flex>
      <DoneAction />
    </>
  );
};
