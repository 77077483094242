import React from 'react';
import styled from '@emotion/styled';
import { VolumeControl } from './volume-control';
import { DraggableCancel } from '@shared/constants';
import { FullscreenButton } from './fullscreen-button';

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive?: boolean }) => ({
  gap: 16,
  bottom: 8,
  zIndex: 10,
  display: 'flex',
  borderRadius: 99,
  padding: '4px 12px',
  alignItems: 'center',
  position: 'absolute',
  transition: 'right 0.3s',
  justifyContent: 'center',
  right: !isActive ? 8 : 26,
  backdropFilter: 'blur(22px)',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
}));

export const VideoActions: React.FC<{
  muted: boolean;
  isActive?: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ muted, videoRef, setMuted, setVisible, isActive }) => {
  return (
    <Container isActive={isActive} className={DraggableCancel}>
      <VolumeControl muted={muted} videoRef={videoRef} setMuted={setMuted} />
      <FullscreenButton setVisible={setVisible} videoRef={videoRef} />
    </Container>
  );
};
