import styled, { CSSObject } from '@emotion/styled';

export const StyledTarget = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ height }: { height?: CSSObject['height'] }) => ({
  left: 0,
  bottom: 0,
  zIndex: -1,
  width: '100%',
  position: 'absolute',
  height: height || '100%',
}));
