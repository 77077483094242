import { Button, Divider } from 'antd';
import { prefixCls } from '@shared/theme';
import { EditOutlined } from '@ant-design/icons';
import styled, { CSSObject } from '@emotion/styled';

export const CTAButton = styled(Button, {
  shouldForwardProp: (propName) =>
    !['color', 'backgroundColor'].includes(propName),
})<{
  color?: CSSObject['color'];
  backgroundColor?: CSSObject['backgroundColor'];
}>(({ color, backgroundColor }) => ({
  fontWeight: 500,
  position: 'relative',
  [`&.${prefixCls}-btn-primary:not(:disabled):not(.${prefixCls}-btn-disabled)`]:
    {
      color,
      backgroundColor,
      boxShadow: backgroundColor,
      '&:hover': {
        backgroundColor: `${backgroundColor}E0`,
      },
    },
}));

export const EditIcon = styled(EditOutlined, {
  shouldForwardProp: (propName) => propName !== 'primaryColor',
})(({ primaryColor }: { primaryColor?: CSSObject['color'] }) => ({
  cursor: 'pointer',
  color: primaryColor,
}));

export const StyledDivider = styled(Divider, {
  shouldForwardProp: (propName) =>
    !['isLarge', 'dataServer'].includes(propName),
})(({ isLarge, dataServer }: { isLarge?: boolean; dataServer?: boolean }) => ({
  marginBottom: isLarge ? '1.2rem' : '1rem',
  marginTop: isLarge ? (dataServer ? '1.6rem' : '1.2rem') : '1rem',
}));
