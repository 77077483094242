import React from 'react';
import { prefixCls } from '@shared/theme';
import { CSSObject } from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const cardStyles: CSSObject = {
  padding: '2.4rem',
  borderRadius: '2.8rem',
};

export const cardBodyStyles = (size: builderCardSizes): CSSObject => ({
  justifyContent: 'space-between',
  gap: size === builderCardSizes.s14 ? '12rem' : '3.8rem',
  flexDirection: size === builderCardSizes.s22 ? 'row' : 'column',
  '& > div': {
    width: size === builderCardSizes.s22 ? '50%' : '100%',
  },
});

export const cardBodyHeaderStyles = (size: builderCardSizes): CSSObject => ({
  gap: '1rem',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: [
    builderCardSizes.s12,
    builderCardSizes.s22,
    builderCardSizes.s14,
  ].includes(size)
    ? 'column'
    : 'row',
});

export const cardBodyHeaderDetailsStyles = (
  size: builderCardSizes,
): CSSObject => ({
  flexDirection: 'column',
  gap: [builderCardSizes.s12, builderCardSizes.s22].includes(size)
    ? '.6rem'
    : '1.4rem',
});

export const linkStyles: CSSObject = {
  gap: '.6rem',
  alignItems: 'center',
};

export const imageStyles: React.CSSProperties = {
  objectFit: 'cover',
  objectPosition: 'center',
};

export function imageContainerStyles(size: builderCardSizes): CSSObject {
  return {
    flexGrow: 1,
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    borderRadius: size === builderCardSizes.s24 ? '1.6rem' : '1rem',
  };
}

export const sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw';

export const dividerStyles: React.CSSProperties = {
  height: '2rem',
  marginTop: 'auto',
  position: 'static',
  marginBottom: 'auto',
  backgroundColor: '#FFFFFF80',
};

export const uploadStyles: CSSObject = {
  display: 'flex',
  [`& .${prefixCls}-upload`]: {
    display: 'flex',
  },
};

export const linkOutlinedStyles: React.CSSProperties = {
  color: 'white',
  fontSize: '2.4rem',
};

export const imageSkeletonStyles: CSSObject = {
  [`&.${prefixCls}-skeleton.${prefixCls}-skeleton-element`]: {
    width: '100%',
    height: '100%',
    [`& > .${prefixCls}-skeleton-image`]: {
      width: '100%',
      height: '100%',
    },
  },
};

export const titleSkeletonStyles: CSSObject = {
  [`&.${prefixCls}-skeleton.${prefixCls}-skeleton-active`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-title`]: {
        margin: 0,
        height: 22,
        marginBottom: '.8rem',
      },
      [`& > .${prefixCls}-skeleton-paragraph`]: {
        margin: 0,
        '& > li': {
          height: 22,
        },
      },
    },
  },
};

export const cardBodyHeaderDetailsTitleStyles: React.CSSProperties = {
  top: 0,
  left: 0,
  width: '100%',
  paddingLeft: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
