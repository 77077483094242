import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const SkeletonImage = styled(Skeleton.Node)(() => ({
  [`&.${prefixCls}-skeleton.${prefixCls}-skeleton-element`]: {
    width: '100%',
    height: '100%',
    [`& > .${prefixCls}-skeleton-image`]: {
      width: '100%',
      height: '100%',
    },
  },
}));
