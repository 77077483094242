import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import styled from '@emotion/styled';
import { blurDataURL } from '@shared/constants';
import fallback from '@shared/icons/fallback.png';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { PostSkeleton } from '@widgets/social-card/ui/post-skeleton';

const keys = [uuidV4(), uuidV4(), uuidV4(), uuidV4()];

const fallbackPosts = [
  { image: blurDataURL },
  { image: blurDataURL },
  { image: blurDataURL },
  { image: blurDataURL },
];

const ImageCover = styled('span')(() => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  userSelect: 'none',
  position: 'absolute',
  backgroundColor: 'transparent',
}));

const imageStyles = (size: builderCardSizes): React.CSSProperties => {
  const isS14 = size === builderCardSizes.s14;

  return {
    objectFit: 'cover',
    borderRadius: '8px',
    objectPosition: 'center',
    maxHeight: isS14 ? 56 : 'unset',
  };
};

const PostsContainer = styled('div')(({ size }: { size: builderCardSizes }) => {
  let gTColumn = `1fr`;
  let gridTemplateColumns = `${gTColumn} ${gTColumn}`;

  const isS14 = size === builderCardSizes.s14;

  let additionalStyles = {};

  if (isS14) {
    additionalStyles = {
      ...additionalStyles,
      alignContent: 'end',
      gridTemplateRows: '56px 56px',
    };
  }

  return {
    gap: '1rem',
    height: '100%',
    display: 'grid',
    gridTemplateColumns,
    position: 'relative',
    ...additionalStyles,
  };
});

const ImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size: builderCardSizes }) => {
  const isS14 = size === builderCardSizes.s14;
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: isS14 ? 56 : 'unset',
  };
});

const Posts: React.FC<{
  size: builderCardSizes;
  showSkeleton?: boolean;
  posts?: { link?: string; image: string }[];
}> = ({ size, posts, showSkeleton }) => {
  const isS12 = size !== builderCardSizes.s12;

  posts = posts?.slice(0, 4);
  if (!posts?.length) {
    posts = fallbackPosts;
  }

  return isS12 ? (
    showSkeleton ? (
      <PostsContainer size={size}>
        <ImageCover />
        {keys.map((uuid) => (
          <ImageContainer key={`skeleton-${uuid}`} size={size}>
            <PostSkeleton size={size} paragraph={false} active={showSkeleton} />
          </ImageContainer>
        ))}
      </PostsContainer>
    ) : (
      <PostsContainer size={size}>
        <ImageCover />
        {posts.map(({ image }, index) => (
          <ImageContainer key={keys[index]} size={size}>
            <ImageWithFallback
              fill
              quality={100}
              sizes="100% 100%"
              placeholder="blur"
              alt="Instagram Post"
              fallbackSrc={fallback}
              style={imageStyles(size)}
              blurDataURL={blurDataURL}
              src={image?.trim() || fallback}
            />
          </ImageContainer>
        ))}
      </PostsContainer>
    )
  ) : null;
};

export const MemoYouTubePosts = React.memo(Posts);
