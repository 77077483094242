import React from 'react';
import { OvalHorizontalIcon } from '@shared/ui/Icons';
import { ActionButton } from '@shared/ui/action-button';
import type { ShapeActionProps } from '../types';

export const OvalHorizontalAction: React.FC<ShapeActionProps> = ({
  setSizes,
  isActive,
}) => {
  return (
    <ActionButton tabIndex={-1} onClick={setSizes}>
      <OvalHorizontalIcon isActive={isActive} />
    </ActionButton>
  );
};
