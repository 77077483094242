export enum AppRoutes {
  index = '/',
  login = '/login',
  base = '/dashboard',
  dashboard = '/dashboard',
  templates = '/templates',
  registerStep1 = '/register/step-1',
  registerStep2 = '/register/step-2',
  forgotPassword = '/forgot-password',
}
