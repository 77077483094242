import React from 'react';
import { Box } from '@shared/ui/box';
import { Resize22 } from './resize22';
import { Resize24 } from './resize24';
import { Divider, Flex, Modal } from 'antd';
import { DoneAction } from './done-action';
import { DraggableCancel } from '@shared/constants';
import { ActionButton } from '@shared/ui/action-button';
import type { ImageBottomActionsProps } from '../types';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { CRUDServiceList } from '@widgets/mediakit/MediaKit/Common/CRUDServiceList';
import {
  useDispatch,
  useSelector,
  selectExtraData,
  selectActiveItem,
  selectCurrentLayout,
  onUpdateExtraDataItem,
  onUpdateReactGridItem,
} from '@shared/redux';

export const ServiceListBottomActions: React.FC<ImageBottomActionsProps> = ({
  activeSize,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const extraData = useSelector(selectExtraData);
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isWarningModalIsOpen, setWarningModalIsOpen] = React.useState(false);

  const activeIndex = layout.findIndex((e) => e.i === activeItem);

  const dataGrid = layout[activeIndex];

  const data = extraData[activeItem as any] as any;

  const addServiceItem = (data: any) => {
    setIsModalOpen(false);

    let value: any = {
      title: data.title,
    };

    if (data.rateIsEnabled) {
      value = {
        title: data.title,
        rate: Number(data.rate),
        currency: data.localCurrency.value || data.localCurrency,
      };
    }

    dispatch(
      onUpdateExtraDataItem({
        i: dataGrid.i,
        item: {
          value,
          key: 'value',
        },
      }),
    );

    try {
      posthog?.capture(PosthogEvents.ServiceListAddRates);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <>
      <Modal
        width={400}
        title={null}
        closeIcon={null}
        className={DraggableCancel}
        open={isWarningModalIsOpen}
        onCancel={() => setWarningModalIsOpen(false)}
        onOk={() => {
          setWarningModalIsOpen(false);
          setIsModalOpen(true);

          const [w, h] = builderCardSizes.s24.split('');

          dispatch(
            onUpdateReactGridItem({ i: activeIndex, item: { w: +w, h: +h } }),
          );
        }}
      >
        <Box boxStyles={{ alignItems: 'flex-start', gap: 16 }}>
          <InfoCircleFilled style={{ fontSize: 21, color: '#5956E9' }} />
          <div>
            The card size will be increased to allow the addition of more
            services.
          </div>
        </Box>
      </Modal>
      <Flex gap=".6rem" align="center">
        <Resize22
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s22}
        />
        <Resize24
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s24}
        />
        {data?.length < 5 && (
          <>
            <Divider
              type="vertical"
              style={{
                height: '2rem',
                marginTop: 'auto',
                position: 'static',
                marginBottom: 'auto',
                backgroundColor: '#FFFFFF80',
              }}
            />
            <ActionButton
              onClick={() => {
                if (activeSize === builderCardSizes.s22 && data.length >= 2) {
                  setWarningModalIsOpen(true);
                } else {
                  setIsModalOpen(true);
                }
              }}
            >
              <PlusOutlined style={{ color: 'white', fontSize: 16 }} />
            </ActionButton>
            {isModalOpen && (
              <CRUDServiceList
                data={dataGrid}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                addServiceItem={addServiceItem}
              />
            )}
          </>
        )}
      </Flex>
      <DoneAction />
    </>
  );
};
