'use client';

import React from 'react';
import Image from 'next/image';
import type { ImageProps } from 'next/image';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

const ImageWithFallback = ({
  src,
  alt,
  fallbackSrc,
  ...rest
}: ImageProps & {
  fallbackSrc: StaticImport | string;
}) => {
  const [imgSrc, setImgSrc] = React.useState(src || fallbackSrc);

  React.useEffect(() => {
    setImgSrc(() => {
      return src;
    });
  }, [src]);

  const onError = React.useCallback(() => {
    setImgSrc(fallbackSrc);
  }, [fallbackSrc]);

  return <Image {...rest} alt={alt} src={imgSrc} onError={onError} />;
};

export default ImageWithFallback;
