import { api } from './api';
import { urlHelper } from '@/lib/utils/urlHelper';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

interface FetchCountriesRT {
  data: {
    countries: { code: string; name: string }[];
  };
}

export const contactCreatorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    submitContactForm: builder.mutation<any, any>({
      transformErrorResponse,
      query: ({ username, ...formData }) => ({
        method: 'POST',
        body: formData,
        url: urlHelper(ApiEndpoints.contactCreator, { username }),
      }),
    }),
    submitServiceContactForm: builder.mutation<any, any>({
      transformErrorResponse,
      query: ({ username, ...formData }) => ({
        method: 'POST',
        body: formData,
        url: urlHelper(ApiEndpoints.contactCreatorService, { username }),
      }),
    }),
  }),
});

export const {
  endpoints,
  useSubmitContactFormMutation,
  useSubmitServiceContactFormMutation,
} = contactCreatorApi;
