import React from 'react';
import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { StyledUpload } from './styled';
import { allowedImages } from '@shared/constants';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useUploadAvatarMutation } from '@/lib/services/mediakit';

export function AvatarUploader({
  slug,
  style,
  noStyle,
  children,
}: {
  slug: string;
  username: string;
  noStyle?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  const [upload] = useUploadAvatarMutation();

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const customRequest = ({ file }: UploadRequestOption) => {
    const formData = new FormData();
    formData.append('image', file);

    upload({ data: formData, slug });
  };

  return (
    <StyledUpload
      name="image"
      method="POST"
      noStyle={noStyle}
      id="avatarUploader"
      width={style?.width}
      showUploadList={false}
      height={style?.height}
      listType="picture-circle"
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      accept={allowedImages.join(',')}
    >
      {children}
    </StyledUpload>
  );
}
