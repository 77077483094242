'use client';

import clsx from 'clsx';
import React from 'react';
import { Property } from 'csstype';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { Builder } from '@/lib/types/builder';
import { hexToRgb } from '@/lib/utils/hexToRgb';
import { CheckOutlined } from '@ant-design/icons';
import { DraggableCancel } from '@shared/constants';
import { ActionButton } from '@shared/ui/action-button';
import { Divider, Input, Popover, Skeleton } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { useSkeletonLoader } from '@/lib/hooks/useSkeletonLoader';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { textContrastChecker } from '@/lib/utils/textContrastChecker';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onUpdateExtraDataItem,
} from '@shared/redux';
import {
  ColorOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@shared/ui/Icons';

function InputValue({
  uid,
  value,
  dataServer,
}: {
  uid: string;
  value?: string;
  dataServer?: boolean;
}) {
  const dispatch = useDispatch();
  const [localValue, setLocalValue] = React.useState('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          value: localValue?.trim(),
          key: e.target.name as 'value',
        },
      }),
    );
  };

  const handleInputOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.classList.add(DraggableCancel);
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  return (
    <Input.TextArea
      name="value"
      maxLength={500}
      autoSize={false}
      autoComplete="off"
      value={localValue}
      variant="borderless"
      readOnly={dataServer}
      onBlur={handleInputOnBlur}
      placeholder="Add a note..."
      onFocus={handleInputOnFocus}
      className={fontVariants.opt2}
      onChange={handleInputOnChange}
    />
  );
}

const alignmentIcons = {
  left: AlignLeftOutlined,
  right: AlignRightOutlined,
  center: AlignCenterOutlined,
};

const colors = [
  '#F5222D',
  '#FA8C16',
  '#FAAD14',
  '#FADB14',
  '#A0D911',
  '#52C41A',
  '#13C2C2',
  '#1677FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96',
  '#825A2C',
  '#FFDD9D',
  '#FFFFFF',
  '#8E8E93',
  '#000000',
];

export const ExtraActions = ({
  uuid,
  align,
  color: selectedColor,
}: {
  uuid: string;
  color: string;
  align?: string;
}) => {
  const dispatch = useDispatch();
  const [colorIsActive, setColorIsActive] = React.useState(false);
  const [alignmentIsActive, setAlignmentIsActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>(
    selectedColor?.replace('#', ''),
  );

  const changeColor = (color: string) => () => {
    dispatch(
      onUpdateExtraDataItem({
        i: uuid,
        item: {
          value: color,
          key: 'bg_color',
        },
      }),
    );
  };

  const changeAlignment = (alignment: string) => () => {
    dispatch(
      onUpdateExtraDataItem({
        i: uuid,
        item: {
          key: 'align',
          value: alignment,
        },
      }),
    );
  };

  const alignment = align || 'left';
  const Icon = (alignmentIcons as any)[alignment];

  return (
    <>
      <Divider
        type="vertical"
        style={{
          height: '2rem',
          marginTop: 'auto',
          position: 'static',
          marginBottom: 'auto',
          backgroundColor: '#FFFFFF80',
        }}
      />
      <Popover
        trigger="click"
        placement="bottom"
        className={DraggableCancel}
        rootClassName={DraggableCancel}
        onOpenChange={(visible) => setAlignmentIsActive(visible)}
        content={
          <>
            <style>
              {`.${prefixCls}-popover .${prefixCls}-popover-arrow::before { background: #001529 }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner .${prefixCls}-popover-inner-content { display: flex }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner { background-color: #001529; padding:.5rem; border-radius: 1rem }`}
            </style>
            <ActionButton onClick={changeAlignment('left')}>
              <AlignLeftOutlined isActive={align === 'left' || !align} />
            </ActionButton>
            <ActionButton onClick={changeAlignment('center')}>
              <AlignCenterOutlined isActive={align === 'center'} />
            </ActionButton>
            <ActionButton onClick={changeAlignment('right')}>
              <AlignRightOutlined isActive={align === 'right'} />
            </ActionButton>
          </>
        }
      >
        <ActionButton>
          <Icon isActive={alignmentIsActive} />
        </ActionButton>
      </Popover>

      <Popover
        trigger="click"
        placement="bottomRight"
        className={DraggableCancel}
        rootClassName={DraggableCancel}
        onOpenChange={(visible) => setColorIsActive(visible)}
        content={
          <>
            <style>
              {`.${prefixCls}-popover .${prefixCls}-popover-arrow::before { background: #001529 }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner .${prefixCls}-popover-inner-content { display: flex }`}
              {`.${prefixCls}-popover .${prefixCls}-popover-inner { background-color: #001529; padding:.5rem; border-radius: 1rem }`}
            </style>
            <Box
              boxStyles={{
                gap: 8,
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                boxStyles={{
                  gap: 8,
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                }}
              >
                {colors.map((color) => {
                  const { r, g, b } = hexToRgb(color);
                  const textContrast = textContrastChecker(r, g, b);

                  return (
                    <ActionButton
                      key={color}
                      onClick={() => {
                        changeColor(color)();

                        setInputValue(color.replace('#', ''));
                      }}
                    >
                      <Box
                        boxStyles={{
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11" cy="11" r="10" fill={color} />
                          <circle
                            cx="11"
                            cy="11"
                            r="10.5"
                            stroke="white"
                            strokeOpacity={0.25}
                          />
                        </svg>
                        {color === selectedColor && (
                          <CheckOutlined
                            style={{
                              fontSize: 14,
                              position: 'absolute',
                              color: textContrast,
                            }}
                          />
                        )}
                      </Box>
                    </ActionButton>
                  );
                })}
              </Box>

              <Box
                boxStyles={{
                  [`& .${prefixCls}-input`]: {
                    border: 'none',
                    paddingLeft: 2,
                    color: '#959595',
                    backgroundColor: '#51525A',
                    textTransform: 'uppercase',
                  },
                  [`& .${prefixCls}-input-group-addon`]: {
                    border: 'none',
                    paddingRight: 0,
                    color: '#959595',
                    backgroundColor: '#51525A',
                  },
                }}
              >
                <Input
                  maxLength={6}
                  addonBefore="#"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value?.trim())}
                  onBlur={() => {
                    if (inputValue.length === 6) {
                      changeColor(`#${inputValue}`)();
                    }
                  }}
                />
              </Box>
            </Box>
          </>
        }
      >
        <ActionButton>
          <ColorOutlined isActive={colorIsActive} />
        </ActionButton>
      </Popover>
    </>
  );
};

export function TextComponent({
  data,
  index,
  dataGrid,
  dataServer,
  disableSkeleton,
  showSkeleton: loading,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);
  const showSkeleton = useSkeletonLoader(disableSkeleton);
  const size = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  let rows = 3;
  if ([builderCardSizes.s24, builderCardSizes.s14].includes(size)) {
    rows = 9;
  }

  return (
    <InfoCardContainer
      key={dataGrid.i}
      data-grid={dataGrid}
      className={clsx(activeItem === dataGrid.i && 'active-item')}
    >
      <InfoCardBody>
        {showSkeleton || loading ? (
          <InfoCardBodySkeleton
            active
            paragraph={{ rows }}
            showSkeleton={showSkeleton || loading}
          />
        ) : (
          <InfoCardMeta
            color={data?.bg_color}
            showSkeleton={showSkeleton || loading}
            textAlign={data?.align as Property.TextAlign}
          >
            <InputValue
              uid={dataGrid.i}
              value={data?.value}
              dataServer={dataServer}
            />
          </InfoCardMeta>
        )}
      </InfoCardBody>

      <Actions
        index={index}
        dataGrid={dataGrid}
        extraActions={
          <ExtraActions
            uuid={dataGrid.i}
            align={data.align}
            color={data.bg_color || '#000000'}
          />
        }
      />
    </InfoCardContainer>
  );
}

const InfoCardBody = styled('div')(() => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
}));

const InfoCardContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  borderRadius: '2.8rem',
  flexDirection: 'column',
}));

const InfoCardBodySkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['showSkeleton'].includes(prop),
})<{ showSkeleton?: boolean }>(({ showSkeleton }) => ({
  padding: '1.6rem',
  display: showSkeleton ? 'flex' : 'none',
  [`& .${prefixCls}-skeleton-content .${prefixCls}-skeleton-title`]: {
    marginBottom: 0,
    display: 'flex',
  },
}));

const InfoCardMeta = styled('div', {
  shouldForwardProp: (prop) =>
    !['color', 'textAlign', 'showSkeleton'].includes(prop),
})(
  ({
    color,
    textAlign,
    showSkeleton,
  }: {
    color?: string;
    showSkeleton?: boolean;
    textAlign?: Property.TextAlign;
  }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '2.4rem',
    alignItems: 'baseline',
    opacity: showSkeleton ? 0 : 1,
    [`& textarea.${prefixCls}-input`]: {
      color,
      width: '100%',
      resize: 'none',
      height: '100%',
      padding: '1.6rem',
      fontSize: '1.6rem',
      textAlign: textAlign || 'left',
    },
  }),
);
