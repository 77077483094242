import React from 'react';
import { OvalVerticalIcon } from '@shared/ui/Icons';
import { ActionButton } from '@shared/ui/action-button';
import type { ShapeActionProps } from '../types';

export const OvalVerticalAction: React.FC<ShapeActionProps> = ({
  setSizes,
  isActive,
}) => {
  return (
    <ActionButton tabIndex={-1} onClick={setSizes}>
      <OvalVerticalIcon isActive={isActive} />
    </ActionButton>
  );
};
