import React from 'react';
import { DeleteOutlined } from '@shared/ui/Icons';
import { DraggableCancel } from '@shared/constants';
import { ActionButton } from '@shared/ui/action-button';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { DeleteActionContainer } from './delete-action-container';
import { onRemoveReactGridItem, useDispatch } from '@shared/redux';
import type { CommonActionProps } from '../types';

export const DeleteAction: React.FC<CommonActionProps & { id: string }> = ({
  id,
  index,
  isActive,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();

  const onDelete = () => {
    dispatch(onRemoveReactGridItem(index));

    try {
      const name = id.split('_+_')[0];
      posthog?.capture(PosthogEvents.DeletedComponent, { component: name });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <DeleteActionContainer
      isActive={isActive}
      className={`actions-container ${DraggableCancel}`}
    >
      <ActionButton tabIndex={-1} onClick={onDelete}>
        <DeleteOutlined />
      </ActionButton>
    </DeleteActionContainer>
  );
};
