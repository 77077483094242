import React from 'react';
import { actions } from '../index';
import { Resize12 } from './resize12';
import { Resize22 } from './resize22';
import { Resize14 } from './resize14';
import { Resize24 } from './resize24';
import { DraggableCancel } from '@shared/constants';
import { ActionsContainer } from './actions-container';
import { selectActiveItem, useSelector } from '@shared/redux';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { ResizeOrArrangeTarget } from '@widgets/mkit-tour/ui/resize-or-arrange-target';
import type { ActionsProps } from '../types';

export const ResizeActions: React.FC<ActionsProps> = ({
  index,
  dataGrid,
  allowedSizes,
  extraActions,
  disableActions,
}) => {
  const activeSize = `${dataGrid.w}${dataGrid.h}`;
  const activeItem = useSelector(selectActiveItem);

  const isActive = activeItem === dataGrid.i;

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return !disableActions ? (
    <ActionsContainer
      ref={containerRef}
      isActive={isActive}
      className={`actions-container ${DraggableCancel}`}
    >
      {isActive && <ResizeOrArrangeTarget />}
      {allowedSizes ? (
        <>
          {allowedSizes.map((allowedSize) => {
            const Action = actions[allowedSize];
            return (
              <Action
                index={index}
                key={allowedSize}
                isActive={activeSize === allowedSize}
              />
            );
          })}
          {!!extraActions && extraActions}
        </>
      ) : (
        <>
          <Resize12
            index={index}
            isActive={activeSize === builderCardSizes.s12}
          />
          <Resize22
            index={index}
            isActive={activeSize === builderCardSizes.s22}
          />
          <Resize14
            index={index}
            isActive={activeSize === builderCardSizes.s14}
          />
          <Resize24
            index={index}
            isActive={activeSize === builderCardSizes.s24}
          />
          {!!extraActions && extraActions}
        </>
      )}
    </ActionsContainer>
  ) : (
    isActive && <ResizeOrArrangeTarget />
  );
};
