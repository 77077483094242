import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const Card = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  padding: '1.6rem',
  borderRadius: '2.4rem',
  flexDirection: 'column',
}));

export const CardBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
}));

export const CardTitle = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  marginBottom: '2.4rem',
  [`& > .${prefixCls}-typography`]: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
  },
}));

export const CardTitleSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 120,
    height: '2rem',
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-title`]: {
        marginBottom: 0,
        height: '1.6rem',
      },
    },
  },
}));
