const toFixed = (n: number, fixed: number) => {
  return `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))?.[0] || 0;
};

export function abbreviateNumber(num: number = 0, maximumFractionDigits = 1) {
  let newValue: any = num;

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;

  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = toFixed(newValue, maximumFractionDigits);
  newValue += suffixes[suffixNum];

  return newValue;
}
