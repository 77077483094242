import React from 'react';
import { Resize12 } from './resize12';
import { Resize22 } from './resize22';
import { Resize14 } from './resize14';
import { Resize24 } from './resize24';
import { Uploader } from './uploader';
import { RcFile } from 'antd/es/upload';
import { DoneAction } from './done-action';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { ActionsDivider } from '../ui/actions-divider';
import type { ImageBottomActionsProps } from '../types';
import { ActionButton } from '@shared/ui/action-button';
import { LinkIcon, ReplaceIcon } from '@shared/ui/Icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useUploadImageMutation } from '@/lib/services/mediakit';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Flex, Form, Input, InputRef, message, Modal } from 'antd';
import { allowedImages, DraggableCancel } from '@shared/constants';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import {
  useDispatch,
  useSelector,
  selectExtraData,
  selectActiveItem,
  selectCurrentLayout,
  onUpdateExtraDataItem,
} from '@shared/redux';

export const ImageBottomActions: React.FC<ImageBottomActionsProps> = ({
  slug,
  activeSize,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const inputRef = React.useRef<InputRef>(null);
  const [upload, res] = useUploadImageMutation();
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);
  const [value, handleOnChange] = React.useState('');
  const extraData = useSelector(selectExtraData) as any;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const link = extraData[activeItem as any]?.link;

  React.useEffect(() => {
    handleOnChange(link || '');
  }, [link]);

  React.useEffect(() => {
    return function () {
      handleOnChange('');
    };
  }, []);

  React.useEffect(() => {
    if (res.status === 'fulfilled' && posthog) {
      try {
        posthog?.capture(PosthogEvents.ImageChange);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  }, [res.status, posthog]);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim() || !!link) {
      let newValue = value?.trim();
      if (newValue && !newValue?.startsWith('http')) {
        newValue = `https://${newValue}`;
      }

      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'link',
            value: newValue,
          },
        }),
      );

      if (newValue && newValue !== link) {
        try {
          posthog?.capture(PosthogEvents.ImageAddLink);
        } catch (e) {
          console.error('Posthog error:', e);
        }
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    handleOnChange(link || '');
  };

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const customRequest = ({ file }: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('uid', dataGrid.i);

    upload({
      slug,
      data: formData,
    });
  };

  const activeIndex = layout.findIndex((e) => e.i === activeItem);

  const dataGrid = layout[activeIndex];

  return (
    <>
      <Flex gap=".6rem" align="center">
        <Modal
          okText="Save"
          onOk={handleOk}
          title="Add Link"
          open={isModalOpen}
          maskClosable={false}
          onCancel={handleCancel}
          className={DraggableCancel}
          afterOpenChange={setAutoFocus}
          rootClassName={DraggableCancel}
          width={matches ? undefined : 360}
          okButtonProps={{ disabled: !value?.trim() && !link }}
        >
          <Form size="large" layout="vertical" component="div">
            <Form.Item label="Link">
              <Input
                type="url"
                name="link"
                value={value}
                ref={inputRef}
                autoComplete="off"
                placeholder="www.example.com"
                onChange={(e) => handleOnChange(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Resize12
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s12}
        />
        <Resize22
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s22}
        />
        <Resize14
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s14}
        />
        <Resize24
          index={activeIndex}
          isActive={activeSize === builderCardSizes.s24}
        />
        <>
          <ActionsDivider type="vertical" />
          <Uploader
            name="image"
            method="POST"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            accept={allowedImages.join(',')}
          >
            <ActionButton tabIndex={-1}>
              <ReplaceIcon widthHelper />
            </ActionButton>
          </Uploader>
          <ActionButton tabIndex={-1} onClick={showModal}>
            <LinkIcon widthHelper />
          </ActionButton>
        </>
      </Flex>
      <DoneAction />
    </>
  );
};
