import styled from '@emotion/styled';

export const Mask = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  userSelect: 'none',
  position: 'absolute',
  backgroundColor: 'transparent',
}));
