import styled from '@emotion/styled';

export const LoadingContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));
