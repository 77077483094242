import React from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';

const StyledButton = styled(Button)(() => ({
  border: 'none',
  color: 'white',
}));

const VolumeUpFilled = (
  <svg
    width="24"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M14 20.7251V18.6751C15.5 18.2418 16.7083 17.4084 17.625 16.1751C18.5417 14.9418 19 13.5418 19 11.9751C19 10.4084 18.5417 9.00843 17.625 7.7751C16.7083 6.54176 15.5 5.70843 14 5.2751V3.2251C16.0667 3.69176 17.75 4.7376 19.05 6.3626C20.35 7.9876 21 9.85843 21 11.9751C21 14.0918 20.35 15.9626 19.05 17.5876C17.75 19.2126 16.0667 20.2584 14 20.7251ZM3 15.0001V9.0001H7L12 4.0001V20.0001L7 15.0001H3ZM14 16.0001V7.9501C14.7833 8.31676 15.3958 8.86676 15.8375 9.6001C16.2792 10.3334 16.5 11.1334 16.5 12.0001C16.5 12.8501 16.2792 13.6376 15.8375 14.3626C15.3958 15.0876 14.7833 15.6334 14 16.0001Z"
    />
  </svg>
);

const VolumeOffFilled = (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M19.8004 22.5998L16.7754 19.5748C16.3587 19.8415 15.9171 20.0706 15.4504 20.2623C14.9837 20.454 14.5004 20.6081 14.0004 20.7248V18.6748C14.2337 18.5915 14.4629 18.5081 14.6879 18.4248C14.9129 18.3415 15.1254 18.2415 15.3254 18.1248L12.0004 14.7998V19.9998L7.00039 14.9998H3.00039V8.99981H6.20039L1.40039 4.1998L2.80039 2.7998L21.2004 21.1998L19.8004 22.5998ZM19.6004 16.7998L18.1504 15.3498C18.4337 14.8331 18.6462 14.2915 18.7879 13.7248C18.9296 13.1581 19.0004 12.5748 19.0004 11.9748C19.0004 10.4081 18.5421 9.00814 17.6254 7.77481C16.7087 6.54147 15.5004 5.70814 14.0004 5.2748V3.2248C16.0671 3.69147 17.7504 4.7373 19.0504 6.3623C20.3504 7.98731 21.0004 9.85814 21.0004 11.9748C21.0004 12.8581 20.8796 13.7081 20.6379 14.5248C20.3962 15.3415 20.0504 16.0998 19.6004 16.7998ZM16.2504 13.4498L14.0004 11.1998V7.94981C14.7837 8.31647 15.3962 8.86647 15.8379 9.59981C16.2796 10.3331 16.5004 11.1331 16.5004 11.9998C16.5004 12.2498 16.4796 12.4956 16.4379 12.7373C16.3962 12.979 16.3337 13.2165 16.2504 13.4498ZM12.0004 9.19981L9.40039 6.59981L12.0004 3.9998V9.19981Z"
    />
  </svg>
);

export const VolumeControl: React.FC<{
  muted: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ videoRef, setMuted, muted }) => {
  const onClick = () => {
    if (!videoRef.current) {
      return;
    }

    if (muted) {
      setMuted(false);
      videoRef.current.muted = false;
    } else {
      setMuted(true);
      videoRef.current.muted = true;
    }
  };

  return (
    <StyledButton
      ghost
      size="large"
      type="default"
      onClick={onClick}
      icon={muted ? VolumeOffFilled : VolumeUpFilled}
    />
  );
};
