import styled from '@emotion/styled';
import { fastAnimation } from '@shared/theme';

export const ActionsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive: boolean }) => {
  return {
    bottom: 0,
    zIndex: 10,
    left: '50%',
    gap: '.6rem',
    right: '50%',
    display: 'flex',
    padding: '.5rem',
    border: '1px solid',
    borderRadius: '1rem',
    marginInline: 'auto',
    width: 'fit-content',
    position: 'absolute',
    background: '#001529',
    opacity: isActive ? 1 : 0,
    backgroundColor: '#001529',
    transform: 'translate(-50%, 40px)',
    transition: `opacity ${fastAnimation} ease-in-out`,
    '@media (max-width: 991px)': {
      display: 'none',
    },
  };
});
