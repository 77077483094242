import styled from '@emotion/styled';

export const ActionButton = styled('button')(() => {
  return {
    margin: 0,
    width: 34,
    height: 34,
    padding: 0,
    tabIndex: -1,
    border: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    background: 'transparent',
  };
});
