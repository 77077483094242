import { useEffect, useState } from 'react';

const useBreakpoint = (breakpoint: number) => {
  let m = false;
  if (typeof window === 'object') {
    m = window.matchMedia(`(max-width: ${breakpoint}px)`).matches;
  }

  const [match, setMatch] = useState(m);

  useEffect(() => {
    const handleResize = () => {
      setMatch(window.matchMedia(`(max-width: ${breakpoint}px)`).matches);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return match;
};

export default useBreakpoint;
