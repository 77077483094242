import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const ListItem = styled('div')(() => ({
  gap: 16,
  height: 32,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ListItemTitle = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isLarge',
})(({ isLarge }: { isLarge?: boolean }) => {
  let typographyStyles = {};
  if (!isLarge) {
    typographyStyles = { fontSize: '1.6rem', lineHeight: '2.4rem' };
  }

  return {
    gap: 6,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    [`& > .${prefixCls}-typography`]: {
      marginBottom: 0,
      ...typographyStyles,
    },
  };
});

export const ListItemTitleSkeleton = styled(Skeleton, {
  shouldForwardProp: (propName) => !['index', 'isLarge'].includes(propName),
})(({ index, isLarge }: { isLarge?: boolean; index: number }) => ({
  [`&.${prefixCls}-skeleton`]: {
    display: 'flex',
    alignItems: 'center',
    width: index % 2 ? 120 : 160,
    height: isLarge ? '2.8rem' : '2.4rem',
    [`& > .${prefixCls}-skeleton-content`]: {
      display: 'flex',
      alignItems: 'center',
      [`& > .${prefixCls}-skeleton-title`]: {
        marginBottom: 0,
        height: isLarge ? '2rem' : '1.6rem',
      },
    },
  },
}));
