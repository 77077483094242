import React from 'react';
import { CSSObject } from '@emotion/styled';
import { lightBoxShadowSecondary, prefixCls } from '@shared/theme';

export const cardStyles: CSSObject = {
  borderRadius: '2.4rem',
};

export const cardBodyStyles: CSSObject = {
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '2rem',
};

export const imageStyles: React.CSSProperties = {
  objectFit: 'cover',
  objectPosition: 'center',
};

export const sizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw';

export const skeletonImageStyles: CSSObject = {
  [`&.${prefixCls}-skeleton.${prefixCls}-skeleton-element`]: {
    width: '100%',
    height: '100%',
    [`& > .${prefixCls}-skeleton-image`]: {
      width: '100%',
      height: '100%',
    },
  },
};

export const linkSkeletonStyles: CSSObject = {
  right: 12,
  height: 24,
  bottom: 12,
  zIndex: 10,
  width: 110,
  borderRadius: 20,
  position: 'absolute',
  backgroundColor: '#fff',
};

export const uploadStyles: CSSObject = {
  display: 'flex',
  [`& .${prefixCls}-upload`]: {
    display: 'flex',
  },
};

export const hostnameContainerStyles: CSSObject = {
  zIndex: 10,
  gap: '.6rem',
  display: 'flex',
  right: '1.2rem',
  bottom: '1.2rem',
  overflow: 'hidden',
  width: 'max-content',
  position: 'absolute',
  alignItems: 'center',
  padding: '.2rem 1rem',
  borderRadius: '1.2rem',
  backgroundColor: '#fff',
  maxWidth: 'calc(100% - 2.4rem)',
  boxShadow: lightBoxShadowSecondary,
  border: '1px solid rgba(0, 0, 0, 0.15)',
};

export const linkOutlinedStyles: React.CSSProperties = {
  color: 'white',
  fontSize: '2.4rem',
};

export const dividerStyles: React.CSSProperties = {
  height: '2rem',
  marginTop: 'auto',
  position: 'static',
  marginBottom: 'auto',
  backgroundColor: '#FFFFFF80',
};

export const hostnameStyles: React.CSSProperties = {
  color: '#1677ff',
};
