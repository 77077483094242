import styled from '@emotion/styled';

export const VideoCardBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '2rem',
  alignItems: 'center',
  justifyContent: 'center',
}));
