import { Upload } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const StyledUpload = styled(Upload, {
  shouldForwardProp: (propName) =>
    !['width', 'height', 'noStyle'].includes(propName),
})(({
  width,
  height,
  noStyle,
}: {
  noStyle?: boolean;
  width?: number | string;
  height?: number | string;
}) => {
  if (noStyle) {
    return {
      [`&.${prefixCls}-upload-wrapper`]: {
        padding: 0,
        width: 'max-content',
        height: 'max-content',
        [`& > .${prefixCls}-upload.mediakit-upload-select`]: {
          margin: 0,
          padding: 0,
          border: 'none',
          width: 'max-content',
          height: 'max-content',
          background: 'transparent',
          '&:hover': {
            borderColor: 'none !important',
          },
        },
      },
    };
  }

  return {
    [`&.${prefixCls}-upload-wrapper`]: {
      width,
      height,
      [`& > .${prefixCls}-upload.mediakit-upload-select`]: {
        width,
        height,
        margin: 0,
        background: '#F5F5F5',
        border: '6px solid #fff',
        '&:hover': {
          borderColor: '#fff !important',
        },
      },
    },
  };
});
