import styled from '@emotion/styled';
import { builderBreakpoint } from '@shared/theme';

export const GridContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showSkeleton',
})(({ showSkeleton }: { showSkeleton?: boolean }) => ({
  width: '100%',
  maxWidth: 752,
  marginTop: 80,
  position: 'relative',
  overflow: showSkeleton ? 'hidden' : void 0,
  '&.mobile': {
    maxWidth: 374,
    marginTop: '2.4rem',
    marginBottom: '4rem',
    marginInline: 'auto',
  },
  '& .react-resizable-handle': {
    display: 'none',
  },
  [`@media (max-width: ${builderBreakpoint})`]: {
    marginTop: '2.4rem',
    marginBottom: '4rem',
    marginInline: 'auto',
  },
  [`@media (max-width: 850px)`]: {
    maxWidth: 374,
  },
}));
