import React from 'react';
import Link from 'next/link';
import NextImage from 'next/image';
import styled from '@emotion/styled';
import { Text } from '@shared/ui/typography';
import linkIcon from '@shared/icons/link.svg';
import { lightBoxShadowSecondary } from '@shared/theme';
import { fontVariants } from '@shared/constants/fontVariants';

const Mask = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: 'white',
}));

const HostNameText = styled(Text)(() => ({
  color: '#1677ff',
}));

const HostContainer = styled('div')(() => ({
  zIndex: 10,
  top: '1.2rem',
  gap: '.6rem',
  display: 'flex',
  right: '1.2rem',
  overflow: 'hidden',
  width: 'max-content',
  position: 'absolute',
  alignItems: 'center',
  padding: '.2rem 1rem',
  borderRadius: '1.2rem',
  backgroundColor: '#ffffff',
  maxWidth: 'calc(100% - 2.4rem)',
  boxShadow: lightBoxShadowSecondary,
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

export const Host: React.FC<{
  link?: string;
  isLoading?: boolean | string;
}> = ({ link, isLoading }) => {
  let hostname;

  if (link) {
    try {
      let newLink = link;

      if (!newLink.startsWith('http')) {
        newLink = `https://${link}`;
      }

      const newUrl = new URL(newLink);

      hostname = newUrl.hostname;

      if (hostname.startsWith('www.')) {
        hostname = hostname.replace('www.', '');
      }
    } catch (e) {
      console.error(e);

      hostname = link;
    }
  }

  return link ? (
    <HostContainer>
      {isLoading && <Mask />}
      <Link target="_blank" href={link} className={fontVariants.opt3}>
        {hostname}
      </Link>
      <NextImage src={linkIcon} alt="Open in new window" />
    </HostContainer>
  ) : null;
};
