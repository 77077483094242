import React from 'react';
import { AbcOutlined } from '@shared/ui/AbcOutlined';
import { abbreviateNumber } from '@/lib/utils/abbreviateNumber';
import { ComponentNames } from '@shared/constants/componentNames';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import {
  LinkOutlined,
  LayoutOutlined,
  PictureOutlined,
  FontSizeOutlined,
  VideoCameraOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { GalleryIcon, ImageIcon, VideoIcon } from '@shared/ui/Icons';

const { s12, s14, s22, s24 } = builderCardSizes;

export function getTikTokAccountDetails(): Record<
  string,
  (props: { value?: number; size?: builderCardSizes }) => {
    title: string;
    value?: string;
    tooltip?: string;
    chartTooltipName?: string;
  }
> {
  return {
    [ComponentNames.tiktok_likes_growth]: () => ({
      title: 'Likes’ Growth',
      chartTooltipName: 'Likes',
      tooltip: 'Total number of likes in TikTok account over time.',
    }),
    [ComponentNames.tiktok_top_countries]: () => ({
      title: 'Top Countries',
      tooltip: 'Breakdown of which countries TikTok followers come from.',
    }),
    [ComponentNames.tiktok_followers_growth]: () => ({
      title: 'Followers’ Growth',
      chartTooltipName: 'Followers',
      tooltip: 'Number of TikTok followers over time.',
    }),
    [ComponentNames.tiktok_followers]: ({ value }) => ({
      title: 'Followers',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_avg_saves]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Saves' : 'Average Saves',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_avg_views]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Views' : 'Average Views',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_avg_likes]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Likes' : 'Average Likes',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_avg_shares]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Shares' : 'Average Shares',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_avg_comments]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Comments' : 'Average Comments',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.tiktok_engagement_rate]: ({ size, value }) => ({
      title: size === s12 ? 'Engagement' : 'Engagement Rate',
      value: `${((value || 0) * 100)?.toFixed(2)}%`,
    }),
    [ComponentNames.tiktok_audience_genders]: () => ({
      title: 'Sex Ratio',
      tooltip: 'The ratio of males and females among TikTok followers.',
    }),
    [ComponentNames.tiktok_audience_genders_per_age]: () => ({
      title: 'Sex and Age Ratio',
      tooltip: 'An age and sex ratio breakdown of TikTok follower base.',
    }),
  };
}

export function getYouTubeAccountDetails(): Record<
  string,
  (props: { value?: number; size?: builderCardSizes }) => {
    title: string;
    value?: string;
    tooltip?: string;
    chartTooltipName?: string;
  }
> {
  return {
    [ComponentNames.youtube_views_growth]: () => ({
      title: 'Average Views’ Growth',
      chartTooltipName: 'Average Views',
      tooltip: 'Total number YouTube channel views over time.',
    }),
    [ComponentNames.youtube_top_countries]: () => ({
      title: 'Top Countries',
      tooltip: 'Breakdown of which countries YouTube subscribers come from.',
    }),
    [ComponentNames.youtube_followers_growth]: () => ({
      title: 'Subscribers’ Growth',
      chartTooltipName: 'Subscribers',
      tooltip: 'Number of YouTube channel subscribers over time.',
    }),
    [ComponentNames.youtube_followers]: ({ value }) => ({
      title: 'Followers',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.youtube_avg_views]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Views' : 'Average Views',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.youtube_avg_likes]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Likes' : 'Average Likes',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.youtube_avg_comments]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Comments' : 'Average Comments',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.youtube_engagement_rate]: ({ size, value }) => ({
      title: size === s12 ? 'Engagement' : 'Engagement Rate',
      value: `${((value || 0) * 100)?.toFixed(2)}%`,
    }),
    [ComponentNames.youtube_audience_genders]: () => ({
      title: 'Sex Ratio',
      tooltip: 'The ratio of males and females among YouTube subscribers.',
    }),
    [ComponentNames.youtube_audience_genders_per_age]: () => ({
      title: 'Sex and Age Ratio',
      tooltip: 'An age and sex ratio breakdown of YouTube subscriber base.',
    }),
  };
}

export function getBasicComponentsDetails(): Record<
  string,
  () => {
    title: string;
    icon: React.ReactNode;
  }
> {
  return {
    [ComponentNames.basics_text]: () => ({
      title: 'Text',
      icon: <AbcOutlined />,
    }),
    [ComponentNames.basics_link]: () => ({
      title: 'Link',
      icon: <LinkOutlined />,
    }),
    [ComponentNames.basics_image]: () => ({
      title: 'Image',
      icon: <ImageIcon />,
    }),
    [ComponentNames.basics_video]: () => ({
      title: 'Video',
      icon: <VideoIcon />,
    }),
    [ComponentNames.basics_title]: () => ({
      title: 'Title',
      icon: <FontSizeOutlined />,
    }),
    [ComponentNames.basics_info_card]: () => ({
      title: 'Info Card',
      icon: <LayoutOutlined />,
    }),
    [ComponentNames.basics_service_list]: () => ({
      title: 'Service List',
      icon: <UnorderedListOutlined />,
    }),
    [ComponentNames.basics_gallery]: () => ({
      title: 'Gallery',
      icon: <GalleryIcon />,
    }),
  };
}

export function getInstagramAccountDetails(): Record<
  string,
  (props: { value?: number; size?: builderCardSizes }) => {
    title: string;
    value?: string;
    tooltip?: string;
    chartTooltipName?: string;
  }
> {
  return {
    [ComponentNames.instagram_likes_growth]: () => ({
      title: 'Likes’ Growth',
      chartTooltipName: 'Likes',
      tooltip: 'Total number of likes in Instagram account over time.',
    }),
    [ComponentNames.instagram_top_cities]: () => ({
      title: 'Top Cities',
      tooltip: 'Breakdown of which cities Instagram followers come from.',
    }),
    [ComponentNames.instagram_top_countries]: () => ({
      title: 'Top Countries',
      tooltip: 'Breakdown of which countries Instagram followers come from.',
    }),
    [ComponentNames.instagram_followers_growth]: () => ({
      title: 'Followers’ Growth',
      chartTooltipName: 'Followers',
      tooltip: 'Number of Instagram followers over time.',
    }),
    [ComponentNames.instagram_followers]: ({ value }) => ({
      title: 'Followers',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.instagram_avg_views]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Views' : 'Average Views',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.instagram_avg_likes]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Likes' : 'Average Likes',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.instagram_avg_comments]: ({ size, value }) => ({
      title: size === s12 ? 'Avg Comments' : 'Average Comments',
      value: abbreviateNumber(value || 0),
    }),
    [ComponentNames.instagram_engagement_rate]: ({ size, value }) => ({
      value: `${((value || 0) * 100)?.toFixed(2)}%`,
      title: size === s12 ? 'Engagement' : 'Engagement Rate',
    }),
    [ComponentNames.instagram_avg_reels_plays]: ({ size, value }) => ({
      value: abbreviateNumber(value || 0),
      title: size === s12 ? 'Avg Reels Plays' : 'Average Reels Plays',
    }),
    [ComponentNames.instagram_audience_genders]: () => ({
      title: 'Sex Ratio',
      tooltip: 'The ratio of males and females among Instagram followers.',
    }),
    [ComponentNames.instagram_audience_genders_per_age]: () => ({
      title: 'Sex and Age Ratio',
      tooltip: 'An age and sex ratio breakdown of Instagram follower base.',
    }),
  };
}

export const AllowedSizes = {
  [ComponentNames.tiktok_profile]: [s12, s22, s14, s24],
  [ComponentNames.youtube_profile]: [s12, s22, s14, s24],
  [ComponentNames.instagram_profile]: [s12, s22, s14, s24],

  [ComponentNames.instagram_likes_growth]: [s24],
  [ComponentNames.instagram_avg_views]: [s12, s22],
  [ComponentNames.instagram_followers]: [s12, s22],
  [ComponentNames.instagram_avg_likes]: [s12, s22],
  [ComponentNames.instagram_followers_growth]: [s24],
  [ComponentNames.instagram_avg_comments]: [s12, s22],
  [ComponentNames.instagram_engagement_rate]: [s12, s22],
  [ComponentNames.instagram_avg_reels_plays]: [s12, s22],
  [ComponentNames.instagram_audience_genders_per_age]: [s24],
  [ComponentNames.instagram_audience_genders]: [s12, s22, s14, s24],
  [ComponentNames.instagram_top_countries]: [s12, s22, s14, s24],
  [ComponentNames.instagram_top_cities]: [s12, s22, s14, s24],

  [ComponentNames.tiktok_likes_growth]: [s24],
  [ComponentNames.tiktok_avg_saves]: [s12, s22],
  [ComponentNames.tiktok_followers]: [s12, s22],
  [ComponentNames.tiktok_avg_views]: [s12, s22],
  [ComponentNames.tiktok_avg_likes]: [s12, s22],
  [ComponentNames.tiktok_avg_shares]: [s12, s22],
  [ComponentNames.tiktok_followers_growth]: [s24],
  [ComponentNames.tiktok_avg_comments]: [s12, s22],
  [ComponentNames.tiktok_engagement_rate]: [s12, s22],
  [ComponentNames.tiktok_audience_genders_per_age]: [s24],
  [ComponentNames.tiktok_top_countries]: [s12, s22, s14, s24],
  [ComponentNames.tiktok_audience_genders]: [s12, s22, s14, s24],

  [ComponentNames.youtube_views_growth]: [s22],
  [ComponentNames.youtube_followers]: [s12, s22],
  [ComponentNames.youtube_avg_views]: [s12, s22],
  [ComponentNames.youtube_avg_likes]: [s12, s22],
  [ComponentNames.youtube_followers_growth]: [s24],
  [ComponentNames.youtube_avg_comments]: [s12, s22],
  [ComponentNames.youtube_engagement_rate]: [s12, s22],
  [ComponentNames.youtube_audience_genders_per_age]: [s24],
  [ComponentNames.youtube_top_countries]: [s12, s22, s14, s24],
  [ComponentNames.youtube_audience_genders]: [s12, s22, s14, s24],

  [ComponentNames.basics_text]: [s12, s22],
  [ComponentNames.basics_link]: [s12, s22, s14, s24],
  [ComponentNames.basics_image]: [s12, s22, s14, s24],
  [ComponentNames.basics_video]: [s12, s22],
  [ComponentNames.basics_title]: [s12, s22],
  [ComponentNames.basics_info_card]: [s12, s22],

  [ComponentNames.basics_service_list]: [s12, s22, s14, s24],
  [ComponentNames.basics_gallery]: [s12, s22, s14, s24],
};
