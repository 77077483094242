import React from 'react';
import { DragOutlined } from '@shared/ui/Icons';
import { DragHandler } from '@shared/constants';
import type { CommonActionProps } from '../types';
import { ActionButton } from '@shared/ui/action-button';
import { MoveActionContainer } from './move-action-container';
import { ArrangeMobileTarget } from '@widgets/mkit-tour/ui/arrange-mobile-target';

export const MoveAction: React.FC<CommonActionProps> = ({ isActive }) => {
  return isActive ? (
    <MoveActionContainer
      isActive={isActive}
      className={`actions-container ${DragHandler}`}
    >
      <ActionButton tabIndex={-1} className={DragHandler}>
        <DragOutlined />
      </ActionButton>
      <ArrangeMobileTarget />
    </MoveActionContainer>
  ) : null;
};
