import React from 'react';
import { CSSObject } from '@emotion/styled';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const cardStyles: CSSObject = {
  padding: '1.6rem',
  borderRadius: '2.8rem',
};

export const cardBodyHeaderStyles: CSSObject = {
  gap: '.4rem',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const cardBodyDescriptionStyles: CSSObject = {
  marginBottom: 0,
};

export const cardBodyHeaderDetailsStyles: CSSObject = {
  gap: '.4rem',
  alignItems: 'center',
};

export const titleTitleProps = {
  width: 111,
  style: { height: 24, margin: 0 },
};

export const descriptionTitleProps = {
  style: {
    height: 46,
    maxWidth: 270,
    marginBottom: 0,
    marginTop: '1rem',
  },
};

export const cardBodyHeaderDetailsTitleStyles = (
  size: builderCardSizes,
): React.CSSProperties => ({
  padding: 0,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  height: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '2rem'
    : '2.4rem',
  lineHeight: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '2rem'
    : '2.4rem',
  fontSize: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
    ? '1.2rem'
    : '1.6rem',
});

export const cardBodyDescriptionTitleStyles = (
  size: builderCardSizes,
): React.CSSProperties => ({
  padding: 0,
  fontWeight: 600,
  lineHeight: '4.6rem',
  textOverflow: 'ellipsis',
  height: builderCardSizes.s12 === size ? '4.6rem' : '6.4rem',
  fontSize: builderCardSizes.s12 === size ? '3.8rem' : '5.5rem',
});

export const cardBodyStyles = (size: builderCardSizes): CSSObject => ({
  flexDirection: 'column',
  gap: size === builderCardSizes.s12 ? '3.4rem' : '2.1rem',
});
