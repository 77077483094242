import React from 'react';
import { ReplaceIcon } from '@shared/ui/Icons';
import { ActionButton } from '@shared/ui/action-button';
import { AvatarUploader } from '@widgets/mediakit/MediaKit/Profile/components/AvatarUploader';
import type { ReplaceActionProps } from '../types';

export const ReplaceAction = ({
  widthHelper,
  ...props
}: ReplaceActionProps) => {
  return (
    <AvatarUploader noStyle {...props}>
      <ActionButton tabIndex={-1}>
        <ReplaceIcon widthHelper={widthHelper} />
      </ActionButton>
    </AvatarUploader>
  );
};
