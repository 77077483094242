'use client';

import { Typography } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { CSSObject } from '@emotion/styled';

const { Title: AntdTitle, Text: AntdText } = Typography;

export const Title = styled(AntdTitle, {
  shouldForwardProp: (propName) => propName !== 'titleProps',
})(({ titleProps }: { titleProps?: CSSObject }) => ({
  [`h1&.${prefixCls}-typography, h2&.${prefixCls}-typography, h3&.${prefixCls}-typography, h4&.${prefixCls}-typography, h5&.${prefixCls}-typography, h6&.${prefixCls}-typography`]:
    {
      marginBottom: 0,
      textAlign: 'center',
      ...(titleProps as any),
    },
}));

export const Text = styled(AntdText, {
  shouldForwardProp: (propName) => propName !== 'textProps',
})(({ textProps }: { textProps?: CSSObject }) => ({
  [`&.${prefixCls}-typography`]: {
    ...textProps,
  },
}));
