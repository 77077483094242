'use client';

import React from 'react';
import Image from 'next/image';
import NextLink from 'next/link';
import * as styles from './styles';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { RcFile } from 'antd/es/upload';
import { LinkIcon, ReplaceIcon } from '@shared/ui/Icons';
import { Text } from '@shared/ui/typography';
import { Builder } from '@/lib/types/builder';
import linkIcon from '@shared/icons/link.svg';
import fallback from '@shared/icons/fallback.png';
import { Layout as ILayout } from 'react-grid-layout';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { ActionButton } from '@shared/ui/action-button';
import defaultLink from '@shared/icons/defaultLink.svg';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { FiniteStates } from '@shared/constants/finiteStates';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Actions } from '@widgets/mediakit/MediaKit/Common/Actions';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { allowedImages, defaultSlug, DraggableCancel } from '@shared/constants';
import {
  Divider,
  Form,
  Input,
  InputRef,
  message,
  Modal,
  Skeleton,
  Upload,
} from 'antd';
import {
  useLinkCrawlMutation,
  useUploadImageMutation,
} from '@/lib/services/mediakit';
import {
  onUpdateExtraDataItem,
  selectActiveItem,
  selectUploadImageStates,
  useDispatch,
  useSelector,
} from '@shared/redux';
import { ErrorBoundary } from '@shared/ui/error-boundary';
import { fontVariants } from '@shared/constants/fontVariants';

const StyledUpload = styled(Upload)(() => styles.uploadStyles);

const TitleSkeleton = styled(Skeleton)(() => styles.titleSkeletonStyles);

const ImageSkeleton = styled(Skeleton.Image)(() => styles.imageSkeletonStyles);

const TitleInput = ({ value, uid }: { value?: string; uid: string }) => {
  const dispatch = useDispatch();

  const [bordered, setBordered] = React.useState(false);

  const [localValue, setLocalValue] = React.useState<string>('');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.add(DraggableCancel);

    setBordered(true);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.classList.remove(DraggableCancel);

    setBordered(false);

    dispatch(
      onUpdateExtraDataItem({
        i: uid,
        item: {
          value: localValue,
          key: e.target.name as 'title',
        },
      }),
    );
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value || '');
  };

  const handleOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  return (
    <Box boxStyles={{ position: 'relative', height: 30 }}>
      <Input
        name="title"
        autoComplete="off"
        value={localValue}
        placeholder="Title"
        variant="borderless"
        onBlur={handleInputOnBlur}
        onFocus={handleInputOnFocus}
        onChange={handleInputOnChange}
        onPressEnter={handleOnPressEnter}
        style={styles.cardBodyHeaderDetailsTitleStyles}
        className={`${fontVariants.opt2} ${DraggableCancel}`}
      />
    </Box>
  );
};

function Content({
  data,
  dataGrid,
  dataServer,
  showSkeleton,
  activeSize: size,
}: {
  dataGrid: ILayout;
  dataServer?: boolean;
  showSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}) {
  const [quality, setQuality] = React.useState(10);

  const uploadImageStates = useSelector(selectUploadImageStates);
  const isLoading = uploadImageStates[dataGrid.i] === FiniteStates.PENDING;
  const isLoadingImage = uploadImageStates[dataGrid.i] === 'uploadImage';

  React.useEffect(() => {
    if (isLoading) {
      setQuality(10);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isLoadingImage) {
      setQuality(10);
    }
  }, [isLoadingImage]);

  return (
    <Box boxStyles={styles.cardBodyStyles(size)}>
      <Box boxStyles={styles.cardBodyHeaderStyles(size)}>
        <Box
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          boxStyles={styles.cardBodyHeaderDetailsStyles(size)}
        >
          {isLoading || showSkeleton ? (
            <Skeleton.Avatar active size={30} />
          ) : (
            <ImageWithFallback
              width={30}
              height={30}
              alt="Instagram"
              fallbackSrc={defaultLink}
              src={data.favicon_url || defaultLink}
            />
          )}
          <Box boxStyles={styles.linkStyles}>
            {dataServer ? (
              <Box boxStyles={{ flexDirection: 'column' }}>
                <Text
                  strong
                  ellipsis={{ tooltip: true }}
                  className={fontVariants.opt2}
                >
                  {data.title}
                </Text>
                <Box
                  boxStyles={{
                    gap: '.5rem',
                    display: 'flex',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Text
                    ellipsis={{ tooltip: true }}
                    className={fontVariants.opt3}
                    type="secondary"
                  >
                    {data.domain}{' '}
                  </Text>
                  <Image src={linkIcon} alt="Open in new window" />
                </Box>
              </Box>
            ) : (
              <Box boxStyles={{ flexDirection: 'column', gap: '.8rem' }}>
                {isLoading || showSkeleton ? (
                  <TitleSkeleton
                    active
                    loading
                    title={{ width: 100 }}
                    paragraph={{ rows: 1, width: 150 }}
                  />
                ) : (
                  <>
                    <TitleInput uid={dataGrid.i} value={data.title} />
                    <ErrorBoundary fallback={<></>}>
                      <NextLink
                        target="_blank"
                        href={data.link || '/'}
                        style={{
                          gap: '.5rem',
                          display: 'flex',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          alignItems: 'center',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Text
                          type="secondary"
                          ellipsis={{ tooltip: true }}
                          className={fontVariants.opt3}
                        >
                          {data.domain}
                        </Text>
                        <Image src={linkIcon} alt="Open in new window" />
                      </NextLink>
                    </ErrorBoundary>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {size !== builderCardSizes.s12 ? (
        <Box boxStyles={styles.imageContainerStyles(size)}>
          <Box
            boxStyles={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              height: '100%',
              userSelect: 'none',
              position: 'absolute',
              backgroundColor: 'transparent',
            }}
          />
          {isLoading || isLoadingImage || showSkeleton ? (
            <ImageSkeleton active></ImageSkeleton>
          ) : (
            <ImageWithFallback
              fill
              quality={quality}
              alt="Instagram Post"
              sizes={styles.sizes}
              fallbackSrc={fallback}
              style={styles.imageStyles}
              onLoad={() => setQuality(100)}
              src={data.image_url || fallback}
            />
          )}
        </Box>
      ) : null}
    </Box>
  );
}

export function Link({
  data,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps) {
  const dispatch = useDispatch();
  const matches = useBreakpoint(400);
  const [craw] = useLinkCrawlMutation();
  const [upload] = useUploadImageMutation();
  const inputRef = React.useRef<InputRef>(null);
  const activeItem = useSelector(selectActiveItem);
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  React.useEffect(() => {
    handleOnChange(data?.link || '');
  }, [data?.link]);

  const [value, handleOnChange] = React.useState('');

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOk = () => {
    setIsModalOpen(false);

    if (value?.trim()) {
      dispatch(
        onUpdateExtraDataItem({
          i: dataGrid.i,
          item: {
            key: 'value',
            value: value?.trim(),
          },
        }),
      );

      craw({
        uid: dataGrid.i,
        slug: defaultSlug,
        link: value?.trim(),
      });
    }
  };

  const showModal = () => setIsModalOpen(true);

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (data?.link) {
      handleOnChange(data?.link);
    }
  };

  const customRequest = async ({ file }: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('uid', dataGrid.i);

    upload({
      data: formData,
      slug: defaultSlug,
    });
  };

  return (
    <Box
      key={dataGrid.i}
      data-grid={dataGrid}
      boxStyles={styles.cardStyles}
      className={activeItem === dataGrid.i ? 'active-item' : ''}
    >
      <Modal
        okText="Save"
        onOk={handleOk}
        title="Add Link"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName={DraggableCancel}
        width={matches ? undefined : 360}
        okButtonProps={{ disabled: !value?.trim() }}
      >
        <Form size="large" layout="vertical" component="div">
          <Form.Item label="Link">
            <Input
              type="url"
              name="link"
              value={value}
              ref={inputRef}
              autoComplete="off"
              placeholder="www.example.com"
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Content
        data={data}
        dataGrid={dataGrid}
        dataServer={dataServer}
        activeSize={activeSize}
        showSkeleton={showSkeleton}
      />

      <Actions
        index={index}
        dataGrid={dataGrid}
        extraActions={
          <>
            <Divider type="vertical" style={styles.dividerStyles} />
            <StyledUpload
              name="image"
              method="POST"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              accept={allowedImages.join(',')}
            >
              <ActionButton>
                <ReplaceIcon widthHelper />
              </ActionButton>
            </StyledUpload>
            <ActionButton onClick={showModal}>
              <LinkIcon widthHelper />
            </ActionButton>
          </>
        }
      />
    </Box>
  );
}
