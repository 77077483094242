import React from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';
import { FullscreenOutlined } from '@ant-design/icons';

const iconStyle: React.CSSProperties = {
  fontSize: 24,
  color: 'white',
};

const StyledButton = styled(Button)(() => ({
  border: 'none',
  color: 'white',
}));

export const FullscreenButton: React.FC<{
  videoRef: React.RefObject<HTMLVideoElement>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ videoRef, setVisible }) => {
  const onClick = () => {
    // setVisible(true);
    videoRef.current?.requestFullscreen();
  };

  return (
    <StyledButton
      ghost
      size="large"
      type="default"
      onClick={onClick}
      icon={<FullscreenOutlined style={iconStyle} />}
    />
  );
};
